import { Action } from '@ngrx/store';

export enum QuestionActionTypes {

  LoadSearchQuestion = '[SearchQuestion] Load SearchQuestion',
  LoadSearchQuestionSuccess = '[SearchQuestion] Load SearchQuestion Success',
  LoadSearchQuestionFail = '[SearchQuestion] Load SearchQuestion Fail',

  LoadQuestionDetails = '[QuestionDetails] Load QuestionDetails',
  LoadQuestionDetailsSuccess = '[QuestionDetails] Load QuestionDetails Success',
  LoadQuestionDetailsFail = '[QuestionDetails] Load QuestionDetails Fail',
}

export class LoadSearchQuestionAction implements Action {
  readonly type = QuestionActionTypes.LoadSearchQuestion;
  constructor(public payload: { question_id: string }) { }
}
export class LoadSearchQuestionActionSuccess implements Action {
  readonly type = QuestionActionTypes.LoadSearchQuestionSuccess;
  constructor(public payload: []) { }
}
export class LoadSearchQuestionActionFail implements Action {
  readonly type = QuestionActionTypes.LoadSearchQuestionFail;
  constructor(public payload: Error) { }
}
export class LoadQuestionDetailsAction implements Action {
  readonly type = QuestionActionTypes.LoadQuestionDetails;
  constructor(public payload: { question_id: string, is_attempt: number }) { }
}
export class LoadQuestionDetailsActionSuccess implements Action {
  readonly type = QuestionActionTypes.LoadQuestionDetailsSuccess;
  constructor(public payload: []) { }
}
export class LoadQuestionDetailsActionFail implements Action {
  readonly type = QuestionActionTypes.LoadQuestionDetailsFail;
  constructor(public payload: Error) { }
}


export type QuectionActions = LoadSearchQuestionAction | LoadSearchQuestionActionSuccess | LoadSearchQuestionActionFail
  | LoadQuestionDetailsAction | LoadQuestionDetailsActionSuccess | LoadQuestionDetailsActionFail;
