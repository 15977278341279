import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mocktest-header',
  templateUrl: './mocktest-header.component.html',
  styleUrls: ['./mocktest-header.component.scss']
})
export class MocktestHeaderComponent implements OnInit {
  isPolicyPage = true;
  timer = 60 * 5;
  constructor(
    public route: ActivatedRoute, public router: Router
  ) { }

  ngOnInit(): void { window.scroll({top:0,left:0,behavior:"smooth"});
    this.route.params.subscribe(data => {
      this.isPolicyPage = data.isPolicy

    })
  }
  onLeftTime(event) {
    if (!!!event.left) {
      Swal.fire({
        icon: 'error',
        title: 'Timeout error',
        allowOutsideClick: false,
      }).then((result) => {
        //c53f9b7df8fbb22dc12d0e859a5c9734
        this.router.navigate(['lms/subject/subjectname/topic/']);
      });
    }
  }
}
