<div class="modal-header card_heading py-2">
  <button type="button" class="close pull-right m-0 p-0" aria-label="Close" (click)="modalRef.hide()">
    <mdb-icon fas icon="times" class="text-white"></mdb-icon>
  </button>
  <h6 class="modal-title w-100 font-weight-bold" id="myModalLabel">
    <mdb-icon fas icon="list-alt" class="mr-1"></mdb-icon> Update Log
  </h6>
</div>
<div class="modal-body p-4">
   <!-- ############# 7.2 begin ############# -->
   <div class="version-block">
    <h3 class="text-primary mb-0" style="font-weight: 500;">Version 7.2</h3>
    <hr>
    <h5 style="font-weight: 400;">Features</h5>
    <ul>
      <li>Added Webinar Registration from webinar section</li>
      <li>Added Study Planner</li>
    </ul>
    <h5 style="font-weight: 400;">Performance Improvements</h5>
    <ul>
      <li>Speed optimisation</li>
      <li>Code optimisation</li>
    </ul>
  </div>
  <!-- ############# 7.2 ends ############# -->
  <br/>
  <!-- ############# 7.1.8 begin ############# -->
  <div class="version-block">
    <h3 class="text-primary mb-0" style="font-weight: 500;">Version 7.1.8</h3>
    <hr>
    <h5 style="font-weight: 400;">Features</h5>
    <ul>
      <li>Added flagged wise WCT questions filter</li>
      <li>Added Practice mode feature in Sims</li>
      <li>Added feature user able to select sims questions more then 10 and maximum 100</li>
    </ul>
    <h5 style="font-weight: 400;">Bug Fixing</h5>
    <ul>
      <li>Fixed WCT question no. issue</li>
      <li>Fixed user able to attempt sims question with  individual subtopic wise</li>
      <li>Fixed WCT summary report issue</li>
    </ul>
  </div>
  <!-- ############# 7.1.8 ends ############# -->
  <br/>
  <!-- ############# 7.1.7 begin ############# -->
  <div class="version-block">
    <h3 class="text-primary mb-0" style="font-weight: 500;">Version 7.1.7</h3>
    <hr>
    <h5 style="font-weight: 400;">Features</h5>
    <ul>
      <li>Added topic wise Attempted questions filter</li>
      <li>Added topic wise Notes questions filter</li>
    </ul>
    <h5 style="font-weight: 400;">Bug Fixing</h5>
    <ul>
      <li>Fixed calculator in both practice-exam view and exam view</li>
      <li>Fixed mobile view responsiveness</li>
    </ul>
  </div>
  <!-- ############# 7.1.7 ends ############# -->
  <br/>
  <!-- ############# 7.1.6 begin ############# -->
  <div class="version-block">
    <h3 class="text-primary mb-0" style="font-weight: 500;">Version 7.1.6</h3>
    <hr>
    <h5 style="font-weight: 400;">Bug Fixing</h5>
    <ul>
      <li>Table responsive view on table type options in MCQ type</li>
      <li>Table responsive view on table type question in MCQ type</li>
      <li>Fixies on iPad view responsiveness</li>
    </ul>
    <h5 style="font-weight: 400;">Performance Improvements</h5>
    <ul>
      <li>Speed optimisation</li>
      <li>Code optimisation</li>
    </ul>
  </div>
  <!-- ############# 7.1.6 ends ############# -->
  <br/>
  <!-- ############# 7.1.5 begin ############# -->
  <div class="version-block">
    <h3 class="text-primary mb-0" style="font-weight: 500;">Version 7.1.5</h3>
    <hr>
    <h5 style="font-weight: 400;">Features</h5>
    <ul>
      <li>Default scroll added in the app</li>
    </ul>
    <h5 style="font-weight: 400;">Bug Fixing</h5>
    <ul>
      <li>Video bouncing issue fixed</li>
    </ul>
    <h5 style="font-weight: 400;">Performance Improvements</h5>
    <ul>
      <li>Speed optimisation</li>
      <li>Code optimisation</li>
    </ul>
  </div>
  <!-- ############# 7.1.5 ends ############# -->
  <br/>
  <!-- ############# 7.1.4 begin ############# -->
  <div class="version-block">
    <h3 class="text-primary mb-0" style="font-weight: 500;">Version 7.1.4</h3>
    <hr>
    <h5 style="font-weight: 400;">Features</h5>
    <ul>
      <li>Ipad Version Responsiveness</li>
      <li>Menu bar fixing in mobile</li>
      <li>Advance answer-view feature added in MCQ practice exam view</li>
    </ul>
    <h5 style="font-weight: 400;">Bug Fixing</h5>
    <ul>
      <li>Single video and multi video view design update</li>
      <li>Fixed unable to click MCQ options in MCQ practice-exam view and exam view</li>
    </ul>
    <h5 style="font-weight: 400;">Performance Improvements</h5>
    <ul>
      <li>New features added for platform speed optimisation</li>
      <li>Code cleanup</li>
    </ul>
  </div>
  <!-- ############# 7.1.4 ends ############# -->
  <br/>
  <!-- ############# 7.1.3 begin ############# -->
  <div class="version-block">
    <h3 class="text-primary mb-0" style="font-weight: 500;">Version 7.1.3</h3>
    <hr>
    <h5 style="font-weight: 400;">Features</h5>
    <ul>
      <li>Mobile Version Responsiveness</li>
      <li>Adding tooltip for information</li>
      <li>Advance note and message box feature added in MCQ practice exam view</li>
      <li>Advance calculator hide, show, minimize and maximize feature</li>
    </ul>

    <h5 style="font-weight: 400;">Performance Improvements</h5>
    <ul>
      <li>New features added for platform speed optimisation</li>
      <li>Code cleanup</li>
    </ul>
  </div>
  <!-- ############# 7.1.3 ends ############# -->
  <br/>
  <!-- ############# 7.1.2 begins ############# -->
  <div class="version-block">
    <h3 class="text-primary mb-0" style="font-weight: 500;">Version 7.1.2</h3>
    <hr>
    <h5 style="font-weight: 400;">Features</h5>
    <ul>
      <li>Adding tooltip for information</li>
      <li>Add note feature in MCQ practice exam view</li>
    </ul>

    <h5 style="font-weight: 400;">Performance Improvements</h5>
    <ul>
      <li>Speed optimisation</li>
      <li>Code optimisation</li>
    </ul>
  </div>
  <!-- ############# 7.1.2 ends ############# -->
  <br/>
  <!-- ############# 7.1.1 begins ############# -->
  <div class="version-block">
    <h3 class="text-primary mb-0" style="font-weight: 500;">Version 7.1.1</h3>
    <hr>
    <h5 style="font-weight: 400;">Features</h5>
    <ul>
      <li>Added MCQ practice mode (exam view)</li>
      <li>Added SIMs search functionality</li>
    </ul>
    <h5 style="font-weight: 400;">Bug Fixes</h5>
    <ul>
      <li>Exam view session scroll</li>
      <li>Unable to attempt unattempted questions</li>
      <li>SIMs loading</li>
      <li>Unattempted MCQ was not fetching correct data</li>
    </ul>

    <h5 style="font-weight: 400;">Performance Improvements</h5>
    <ul>
      <li>Platform speed optimisation</li>
    </ul>
  </div>
  <!-- ############# 7.1.1 Ends ############# -->
</div>

<div class="modal-footer py-0">
  <button mdbBtn type="button" color="danger" size="sm" (click)="modalRef.hide()" mdbWavesEffect style="border-radius: 5px;">Close</button>
</div>
