import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'minutesToTime'
})
export class minutesToTime implements PipeTransform {
  transform(value: number): string {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    return `${this.padZero(hours)}:${this.padZero(minutes)}:00`;
  }

  private padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}