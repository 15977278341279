import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GameplanModalComponent } from './gameplan-modal.component'
import { from } from 'rxjs';
import { RouterModule } from '@angular/router';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { WavesModule } from 'ng-uikit-pro-standard'
import { ButtonsModule, TableModule, TooltipModule, ProgressbarModule, CharCounterModule, ModalModule, AccordionModule } from 'ng-uikit-pro-standard';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { EvaStatusPopupModule } from '../../_my-space/gameplantool/common-modal/eva-status-popup/eva-status-popup.module';
// import { EvaConfirmationComponent } from '../../_my-space/gameplantool/common-modal/eva-confirmation/eva-confirmation.component';
import { EvaConfirmationModule } from '../../_my-space/gameplantool/common-modal/eva-confirmation/eva-confirmation.module';
import { MDBBootstrapModulePro } from "ng-uikit-pro-standard";


@NgModule({
    declarations: [GameplanModalComponent],
    exports: [GameplanModalComponent],
    imports: [
        CommonModule, ButtonsModule,
        TableModule,
        TooltipModule,
        ProgressbarModule,
        AccordionModule, MDBBootstrapModulesPro.forRoot(), RouterModule, NgSelectModule, FormsModule, EvaConfirmationModule,
        MDBBootstrapModulePro, WavesModule
    ]
})
export class GameplanModalModule { }
