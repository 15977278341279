import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { Observable, BehaviorSubject, Subject, throwError } from 'rxjs'
import { map, catchError } from 'rxjs/operators'
import { feedbackUpdate } from 'projects/cpa/src/app/pages/classes-feedback/_models/rating.model'
import { CryptoService } from 'projects/cpa/src/app/simsapp/services/crypto.service'
import { verticalModel } from '../model/vertical.Model'


@Injectable({
	providedIn: 'root',
})
export class iimiUniversityService {
	Url = `${environment.apiUrl}`
	constructor(private http: HttpClient, private cryptoService:CryptoService) { }

	// getuniversitydetails(data) {
	// 	return this.http.post(this.Url + 'getuniversitydetails', data)
	// } 
	getuniversityinfo(data) {
		data = this.cryptoService.encrypt(data);
		return this.http.post(this.Url + 'getuniversityinfo', {request:data})
	}

	getgradedassignments(data) {
		return this.http.post(this.Url + 'getgradedassignments', data)
	}

	getnongradedassignments(data) {
		return this.http.post(this.Url + 'getnongradedassignments', data)
	}

	getProfileDetails() {
		return this.http.post(this.Url + 'student/details', {
			mf_token: localStorage.getItem('mf_token'),
		})
	}

	getWebinarDetails(payload) {
		return this.http.post(this.Url + 'getWebinardetails', payload)
	}

	getiimischedule(payload) {
		payload = this.cryptoService.encrypt(payload);
		return this.http.post(this.Url + 'getiimischedule', {request:payload})
	}

	getiimiabsentvideolist(payload) {
		return this.http.post(this.Url + 'getiimiabsentvideolist', payload)
	}

	callClaimApi(webinar_id) {
		return this.http.post(this.Url + 'claimattendance', {
			webinar_id: webinar_id,
		})
	}

	getiimiVideoRecordings(payload) {
		return this.http.post(this.Url + 'getiimivideorecordings', {
			webinar_id: payload,
		})
	}

	updateIimiVideoRecordings(payload) {
		return this.http.post(this.Url + 'updateiimivideorecordings', payload)
	}

	registerTheWebinar(data) {
		return this.http.post(this.Url + 'admin/webinar/zoomRegister', data)
	}

	getInstructions() {
		return this.http.post(this.Url + 'getIImiInstructionMaterialList', {})
	}

	getMaterialUploads(payload) {
		// console.log("payload", payload);
		
		return this.http.post(this.Url + 'getMaterialUploads', payload)
	}
	getCourseMaterial(payload){
		return this.http.post(this.Url + 'getCourseMaterial', payload)
	}

	jagsomGetEBooks(payload){
		return this.http.post(this.Url + 'jagsom-get-eBooks', payload)
	}

	jagsomGetVideoList(payload){
		return this.http.post(this.Url + 'jagsom-get-video-list', payload)
	}

	getSubjectCourseMapping(payload){
		return this.http.post(this.Url + 'get-subject-course-mapping', payload)
	}

	getModuleData(payload) {
		return this.http.post(this.Url + 'getuniversitydetails', payload)
	}
	createAssessmentTest(payload) {
		return this.http.post(this.Url + 'generateAssessmentForIimi', payload)
	}

	getAllAssessmentTest(payload) {
		return this.http.post(this.Url + 'getAssessmentResultsForIimi', payload)
	}

	updateenableAssignments(playload) {
		return this.http.post(this.Url + 'enableAssignments', playload)
	}
	deleteSeesionDocument(playload) {
		return this.http.post(this.Url + 'deleteSeesionDocument', playload)
	}
	enableTopicAssignments(playload) {
		return this.http.post(this.Url + 'enableTopicAssignments', playload)
	}

	updateTopicMaterial(playload) {
		return this.http.post(this.Url + 'updateTopicMaterial', playload)
	}

	updateWebinarAttendance(payload) {
		return this.http.post(this.Url + 'updateWebinarAttendance', { webinarId: payload })
	}
	deleteTopicSeesionDocument(playload) {
		return this.http.post(this.Url + 'deleteTopicSeesionDocument', playload)
	}
	createTopicAssignments(playload) {
		return this.http.post(this.Url + 'createTopicAssignments', playload)
	}

	getFacultyDropdownList(params) {
		return this.http.post(this.Url + "universityProgramFeedback/getUniversityFacultyDropdownList", params);
	}
	updateUniversityFeedback(params) {
		return this.http.post(this.Url + "universityProgramFeedback/updateUniversityFeedback", params);
	}

	/* Update session feedback  */
	updateWebinarFeedback(data:feedbackUpdate):Observable<feedbackUpdate> {
		return this.http.post<feedbackUpdate>(`${this.Url+ "updatewebinardetailsfeedback"}`, data).pipe(catchError(this.handleError));
	}

	/* Get required data for session feedback */
	getWebinarFeedback(data:any): Observable<any> {
		return this.http.post<any>(`${this.Url+ "getWebinarDetailsFeedback"}`, data).pipe(catchError(this.handleError));
	}

	/* Getting vertical data */
    getVertical(verticalId:number): Observable<verticalModel[]> {
        return this.http.get<verticalModel[]>(`${this.Url}admin/getVerticalMaster/${verticalId}`).pipe(catchError(this.handleError));
    }

	/* Getting vertical data */
    getVerticalList(): Observable<verticalModel[]> {
        return this.http.get<verticalModel[]>(`${this.Url}admin/getVerticalMaster`).pipe(catchError(this.handleError));
    }

	 /* Handling errors */
	 private handleError(error: HttpErrorResponse) {
		let errorMessage = 'Something bad happened; please try again later.';
        if (error.status === 0) {
			// A client-side or network error occurred. Handle it accordingly.
			errorMessage = 'An error occurred:', error.error;
        } else if(error.toString().includes("404")) {
			errorMessage = "Requested data not found";
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong.
			errorMessage = `Backend returned code ${error.status}, body was: `, error.error;
        }
        // Return an observable with a user-facing error message.
        return throwError(errorMessage);
    }

	getUniversityFeedbackQuestionList(params) {
		return this.http.post(this.Url + "universityProgramFeedback/getUniversityFeedbackQuestionList", params);
	}
	// Recrutement Drive

	getMockInterviewList(payload) {
		return this.http.post(this.Url + 'iimiInterviewSchedule', payload)
	}

	getNextWeekWebinarList(payload) {
		return this.http.post(this.Url + 'get-next-week-webinar-list', payload)
	}

	getAllUniversityListForDropdown(payload) {
		return this.http.post(this.Url + 'universityProgramFeedback/getUniversityDropdownList', payload)
	}

	getAllBatchListByUniversityForDropdown(payload) {
		return this.http.post(this.Url + 'get-all-batch-by-university', payload)
	}
	
	createTopicAssignmentsByUniversity(payload){
		return this.http.post(this.Url + 'create-topic-assignments-by-university', payload)
	}
	deleteMaterialUploads(payload) {
		return this.http.post(this.Url + 'deleteMaterialUploads', payload)
	}
	deleteCourseMaterial(payload) {
		return this.http.post(this.Url + 'deleteCourseMaterial', payload)
	}
	deleteTopicAssignmentsByUniversity(payload) {
		return this.http.post(this.Url + 'deleteTopicAssignmentsByUniversity', payload)
	}

	getAllParentBatchListForDropdown(payload){
		return this.http.post(this.Url + 'get-all-parent-batch', payload)
	}

	getMaterailsListlatest(payload){
		return this.http.post(this.Url + 'getMaterailsListlatest', payload)
	}
    getNotificationList() {
        return this.http.get(this.Url + 'getIIMICommunicationEmailNotification')
    }

    updateReadableStatus(payload : any) {
        return this.http.post(this.Url + 'updateReadableStatus' , payload)
    }

	getIImiPendingFeedback(payload):Observable<any> {
		return this.http.post<any>(this.Url + 'getIImiPendingFeedList', payload)
	}

	getMaterailsListByTopic(payload){
		return this.http.post(this.Url + 'getMaterailsListByTopic', payload)
	}

	////
	getStudentDetails(data: any) {
		return this.http.post(this.Url + 'iimiStudentAppUpload', data);
	}


	saveStudentDetails(data: any) {
		return this.http.post(this.Url + 'saveOnlyStudentDetails', data);
	}


	getKYCsubmitted(){
		return this.http.get(this.Url + 'getKYCdetails' )
	}
	
}
