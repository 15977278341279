<div class="d-none d-sm-none d-md-none d-lg-block m-0 p-0">
  <footer class="font-small miles-footer">
    <div class="small text-center text-white py-1">© 2020 Copyright<br> 
      <a class="text-white" href="https://mileseducation.com/">Miles Education Pvt. Ltd.</a>
    </div>
    <div class="py-1 small text-white-50"style="position: absolute;top: 0;right: 20px;">Version - 8
    </div>
  </footer>

</div>
