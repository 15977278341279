import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  message: any
  constructor() { }

  setMessage(data: any){
    this.message = data;
    // console.log('service',this.message)
  }

  getMessage(){
    return this.message;
  }

  /* Common swal model */
  commonPopup(message, icon, confirm, timer?:number):boolean {
    Swal.fire({
      text: message,
      icon: icon,
      confirmButtonText: confirm,
      timer: timer ? timer : 0
    }).then((result: any) => {
      if (result.isConfirmed) {
        return true;
      } else {
        return false;
      }
    });

    return false;
  }

}