import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'evaluationAgency'
})
export class EvaluationAgencyPipe implements PipeTransform {

  transform(value: string): string {
    const splitBy = '/';
    const splittedText = value.split(splitBy);
     return `${ splittedText[0] }`;
  }

}
