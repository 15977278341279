import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'round'
})
export class RoundPipe implements PipeTransform {

  transform(input: number) {
    if (input > 59) {
      return Math.round(input / 60);
    } else {
      return input;
    }

  }

}
