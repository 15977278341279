import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { of } from 'rxjs';
import { AppState } from 'src/app/_clusters/reducers';
import { Store } from '@ngrx/store';
import { SubjectService } from 'src/app/_clusters/services/cma.subject.service';
import {
  LoadSearchQuestionAction, QuestionActionTypes,
  LoadSearchQuestionActionSuccess, LoadSearchQuestionActionFail, LoadQuestionDetailsAction
} from '../actions/question.action';
import { Router } from '@angular/router';

@Injectable()
export class CMAQuestionEffects {
  @Effect({ dispatch: false })
  loadQuestion$ = this.actions$
    .pipe(
      ofType<LoadSearchQuestionAction>(QuestionActionTypes.LoadSearchQuestion),
      mergeMap(
        (action: any) => this.subject.getQuestionIds(action.payload.question_id)
          .pipe(
            map((res: any) => {
              if (res.data && res.status) {
                // this.router.navigate(['/search', res.data[0].txt_question_id]);.
                this.store.dispatch(new LoadQuestionDetailsAction({ question_id: action.payload.question_id, is_attempt: 0 }));
                return new LoadSearchQuestionActionSuccess(res.data);
              }
            }),
            catchError(error => of(new LoadSearchQuestionActionFail(error)))
          )
      ),

    );
  @Effect()
  loadQuestionDeatils$ = this.actions$
    .pipe(
      ofType<LoadQuestionDetailsAction>(QuestionActionTypes.LoadQuestionDetails),
      mergeMap(
        (action: any) => this.subject.getQuestionDeatilsIds(action.payload.question_id, action.payload.is_attempt)
          .pipe(
            map((res: any) => {

              if (res.data && res.status) {
                //// console.log('effect',res.data.encrypt_session_id);
                if (res.data.encrypt_session_id) {
                  this.router.navigate(['/cma/lms/mcq/mcq-practice', res.data.encrypt_session_id]);
                }
                // else {
                //   this.router.navigate(['/search', res.data.questions_list[0].txt_question_id]);
                // }
                return new LoadSearchQuestionActionSuccess(res.data);
              }
            }),
            catchError(error => of(new LoadSearchQuestionActionFail(error)))
          )
      ),

    );
  constructor(
    private actions$: Actions,
    private router: Router,
    private subject: SubjectService,
    private store: Store<AppState>
  ) { }
}
