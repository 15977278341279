import { Action } from '@ngrx/store';
import { User } from '../models/user.model';
// import { ProjectListData } from '../models/project-list.model';

export enum StudentActionTypes {
  LoadStudents = '[Students] Load Students',
  LoadStudentsSuccess = '[Students] Load Students Success',
  LoadStudentsFail = '[Students] Load Students Fail',

}
export class LoadStudentAction implements Action {
  readonly type = StudentActionTypes.LoadStudents;
}
export class LoadStudentActionSuccess implements Action {
  readonly type = StudentActionTypes.LoadStudentsSuccess;
  constructor(public payload: []) { }
}
export class LoadStudentActionFail implements Action {
  readonly type = StudentActionTypes.LoadStudentsFail;
  constructor(public payload: Error) { }
}


export type StudentActions = LoadStudentAction | LoadStudentActionSuccess | LoadStudentActionFail;
