<!-- Modal UI -->

      <div class="modal-content" style="border-radius: 15px !important;">
        <div class="modal-body p-0" style="background-color:transparent !important">
          <div class="jobs_banner" style="border-radius: 2px;">
            <div class="jobs_banner_right">
              <h1>Find <span style="color: #0245bb;">Jobs</span> and Set your <span style="color: #0245bb;">Career Path!</span></h1>
              <p>Welcome to One-of-its-kind bootcamp spread over 4 weeks! <br/><br/>

                Designed in consultation with the industry & public accounting firms (including the Big 4) to ensure job readiness for entry/mid-level roles in:<br/>
                <strong><span>a.</span> International Accounting (US GAAP & IFRS)</strong><br/>
                <strong><span>b.</span> International Auditing (US GAAS & PCAOB AS) and</strong><br/>
                <strong><span>c.</span> International Taxation (US Federal Income Tax).</strong><br/><br/>
                Are you ready to start your dream job? Click here to start your journey !
              </p>
              <button type="button" mdbBtn color="success" class="waves-light ml-0" style="width: 100px; padding: 9px 0px;" mdbWavesEffect (click)="proceedModal()">Proceed</button>
              <button type="button" mdbBtn color="primary" class="waves-light" style="width: 100px; padding: 9px 0px;" aria-label="Close" (click)="hideModal()" mdbWavesEffect>Close</button>
            </div>
            <div class="jobs_banner_left">
              <img src="../assets/img/jobs_img1.jpeg">
            </div>
          </div>
        </div>
      </div>
