import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { finalize, timeout, catchError, tap } from 'rxjs/operators';
import { ToastService } from 'ng-uikit-pro-standard';
// import { IndexdbServiceService } from 'projects/cma/src/app/clusters/services/indexdb-service.service';
import { Router } from '@angular/router';
import { CryptoService } from 'projects/cpa/src/app/simsapp/services/crypto.service';

@Injectable()
export class HttpsInterceptor implements HttpInterceptor {
  constructor(
    private mdbSpinningPreloader: MDBSpinningPreloader,
    // private dbSevice: IndexdbServiceService,
    private toastrService: ToastService,
    private router: Router,
    private cryptoService: CryptoService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // if (!req.url.includes('saveAnswer')) {
    //   this.mdbSpinningPreloader.start();
    // }
    this.mdbSpinningPreloader.start();
    if (localStorage.getItem('islogged_cpa') == '1') {

      if (!req.url.includes('upload') && !req.url.includes('iimiStudentAppUpload') && !req.url.includes('saveOnlyStudentDetails')) {

        if (
          req.url.includes('/fof') &&
          !req.url.includes('/fof/sign-in')
        ) {
          const userToken = localStorage.getItem(environment.fofAuthTokenKey);
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${userToken}`,
              'x-access-token': `${userToken}`,
              'Content-Type': 'application/json',
              'Access-Control-Allow-Credentials': '*',
              'X-Requested-With': 'XMLHttpRequest',
              // tslint:disable-next-line: object-literal-key-quotes
              enctype: 'multipart/form-data',
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              Expires: 'Sat, 01 Jan 2040 12:01:01 GMT',
            },
          });
        } else {
          const userToken = localStorage.getItem(environment.authTokenKey);
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${userToken}`,
              'x-access-token': `${userToken}`,
              'Content-Type': 'application/json',
              'Access-Control-Allow-Credentials': '*',
              'X-Requested-With': 'XMLHttpRequest',
              // tslint:disable-next-line: object-literal-key-quotes
              enctype: 'multipart/form-data',
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              Expires: 'Sat, 01 Jan 2040 12:01:01 GMT',
            },
          });
        }
      } else if (req.url.includes('uploadImage')) {

        const userToken = localStorage.getItem(environment.authTokenKey);
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${userToken}`,
            'x-access-token': `${userToken}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': '*',
            'X-Requested-With': 'XMLHttpRequest',
          },
        });
      } else if (req.url.includes('uploadPost')) {

        const userToken = localStorage.getItem(environment.authTokenKey);
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${userToken}`,
            'x-access-token': `${userToken}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': '*',
            'X-Requested-With': 'XMLHttpRequest',
          },
        });
      } else if (req.url.includes('uploadDocument')  || req.url.includes('iimiStudentAppUpload') || req.url.includes('saveOnlyStudentDetails')) {

        const userToken = localStorage.getItem(environment.authTokenKey);
        // console.log('else upload', userToken);
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${userToken}`,
            'x-access-token': `${userToken}`,
            'X-Requested-With': 'XMLHttpRequest',
            mimeType: 'multipart/form-data',
          },
        });
      } else if (req.url.includes('upload')) {
        // console.log('else upload');
        req = req.clone({
          setHeaders: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        });
      } else {
        // console.log('in else');
        req = req.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': '*',
            'X-Requested-With': 'XMLHttpRequest',
            // tslint:disable-next-line: object-literal-key-quotes
            enctype: 'multipart/form-data',
          },
        });
      }
    } else if (localStorage.getItem('islogged_cma') == '1') {
      // let token = this.dbSevice.getToken();
      if (!req.url.includes('upload')) {
        //// console.log('inside main if');
        if (
          req.url.includes('/fof') &&
          !req.url.includes('/fof/sign-in')
        ) {
          const userToken = localStorage.getItem(environment.fofAuthTokenKey);
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${userToken}`,
              'x-access-token': `${userToken}`,
              'Content-Type': 'application/json',
              'Access-Control-Allow-Credentials': '*',
              'X-Requested-With': 'XMLHttpRequest',
              // tslint:disable-next-line: object-literal-key-quotes
              enctype: 'multipart/form-data',
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              Expires: 'Sat, 01 Jan 2040 12:01:01 GMT',
            },
          });
        } else {
          req = req.clone({
            setHeaders: {
              // Authorization: `Bearer ${token}`,
              // 'x-access-token': `${token}`,
              'Content-Type': 'application/json',
              'Access-Control-Allow-Credentials': '*',
              'X-Requested-With': 'XMLHttpRequest',
              // tslint:disable-next-line: object-literal-key-quotes
              enctype: 'multipart/form-data',
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              Expires: 'Sat, 01 Jan 2040 12:01:01 GMT',
            },
          });
        }
      } else if (req.url.includes('uploadImage')) {
        // const userToken = localStorage.getItem(environment.authTokenKey);
        req = req.clone({
          setHeaders: {
            // Authorization: `Bearer ${token}`,
            // 'x-access-token': `${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': '*',
            'X-Requested-With': 'XMLHttpRequest',
          },
        });
      } else if (req.url.includes('uploadPost')) {
        // const userToken = localStorage.getItem(environment.authTokenKey);
        req = req.clone({
          setHeaders: {
            // Authorization: `Bearer ${token}`,
            // 'x-access-token': `${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': '*',
            'X-Requested-With': 'XMLHttpRequest',
          },
        });
      } else if (req.url.includes('upload')) {
        req = req.clone({
          setHeaders: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        });
      } else {
        req = req.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': '*',
            'X-Requested-With': 'XMLHttpRequest',
            // tslint:disable-next-line: object-literal-key-quotes
            enctype: 'multipart/form-data',
          },
        });
      }
    } 
    else if (localStorage.getItem('islogged_fof') == '1') {
      if (!req.url.includes('upload')) {
        // console.log('inside main if');
        const userToken = localStorage.getItem(environment.fofAuthTokenKey);
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${userToken}`,
            'x-access-token': `${userToken}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': '*',
            'X-Requested-With': 'XMLHttpRequest',
            // tslint:disable-next-line: object-literal-key-quotes
            enctype: 'multipart/form-data',
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: 'Sat, 01 Jan 2040 12:01:01 GMT',
          },
        });
      } else if (req.url.includes('uploadImage')) {
        const userToken = localStorage.getItem(environment.authTokenKey);
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${userToken}`,
            'x-access-token': `${userToken}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': '*',
            'X-Requested-With': 'XMLHttpRequest',
          },
        });
      } else if (req.url.includes('uploadPost')) {
        // console.log('inside main if 3');
        const userToken = localStorage.getItem(environment.authTokenKey);
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${userToken}`,
            'x-access-token': `${userToken}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': '*',
            'X-Requested-With': 'XMLHttpRequest',
          },
        });
      } else if (req.url.includes('uploadDocument')) {
        const userToken = localStorage.getItem(environment.authTokenKey);
        // console.log('else upload', userToken);
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${userToken}`,
            'x-access-token': `${userToken}`,
            'X-Requested-With': 'XMLHttpRequest',
            mimeType: 'multipart/form-data',
          },
        });
      } else if (req.url.includes('upload')) {
        // console.log('else upload');
        req = req.clone({
          setHeaders: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        });
      } else {
        // console.log('in else');
        req = req.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': '*',
            'X-Requested-With': 'XMLHttpRequest',
            // tslint:disable-next-line: object-literal-key-quotes
            enctype: 'multipart/form-data',
          },
        });
      }

      // let token = this.dbSevice.getToken();
      // if (token) {
      //   // console.log('inter........', token)
      //   req = req.clone({
      //     setHeaders: {
      //       Authorization: `Bearer ${token}`,
      //       'x-access-token': `${token}`,
      //       'Content-Type': 'application/json',
      //       'Access-Control-Allow-Credentials': '*',
      //       'X-Requested-With': 'XMLHttpRequest',
      //       // tslint:disable-next-line: object-literal-key-quotes
      //       'enctype': 'multipart/form-data',
      //       'Cache-Control': 'no-cache',
      //       Pragma: 'no-cache',
      //       Expires: 'Sat, 01 Jan 2040 12:01:01 GMT'

      //     },
      //   });
      // }
    } 
    else {
      req = req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': '*',
          'X-Requested-With': 'XMLHttpRequest',
          enctype: 'multipart/form-data',
          'Cache-Control': 'no-cache',
        },
      });
    }

    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          // Modify the response as per requirement
          const modifiedBody = this.modifyResponseBody(event.body);
          event = event.clone({ body: modifiedBody });
        }
      }),
      timeout(60000),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error\
          if (error.status === 0) {
            errorMessage = 'Please check your internet connection';
          } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
        }
        // this.toastrService.error('Please check your internet connection,\n Please refresh');
        return throwError(errorMessage);
      }),
      finalize(() => {
        this.mdbSpinningPreloader.stop();
        // this.ts.warning('Please check your internet connection');
      })
    );
  }


  /* Decrypt the response */
  private modifyResponseBody(body: any): any {
    return this.cryptoService.responseDecrypt(body);
  }
}
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpsInterceptor, multi: true },
];
