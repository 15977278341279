import { DomSanitizer } from "@angular/platform-browser";
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlight',
  })
  export class HighlightSearch implements PipeTransform {
    // @ViewChild('schedule') schedule: ElementRef
  
    constructor(private sanitizer: DomSanitizer) { }
    transform(value: any, args: any): any {
      if (!args) {
        return value;
      }
      const re = new RegExp(args, 'gi');
      const match = value.match(re);
      if (!match) {
        return value;
      }
      const replacedValue = value.replace(
        re,
        `<mark style="background-color:yellow!important">${match[0]}</mark>`
      );
      return this.sanitizer.bypassSecurityTrustHtml(replacedValue);
    }
  }
  