<!-- <div class="spread-modal-content" #modalContent>
    <div style="position: relative;
        resize: both;
        min-width: 600px;
        height: 600px;">
        <div class="modal-header" #modalHeader>
            <span>Spreadsheet</span>
            <button type="button" (click)="triggerSpreadSheet()" aria-hidden="true">
                Save & Close
            </button>
        </div>
        <gc-spread-sheets id="hostDiv" [hostStyle]="hostStyle" (workbookInitialized)="initSpread($event)">
            <gc-worksheet>
            </gc-worksheet>
        </gc-spread-sheets>

    </div>
</div> -->
<!-- (hostStyle)="setStyle($event)" -->
<resize-border [dragHolder]="header" (sizeStyle)="setStyle($event)">
    <div #header class="spread-modal-header">
        <h4 class="modal-title">Spreadsheet</h4>
        <button type="button" (click)="triggerSpreadSheet()" aria-hidden="true">
            Save & Close
        </button>
    </div>
    <div style="width: auto; min-height: 0px; position: relative;left: 0; top: 0; overflow: hidden; z-index: 9999;"
        [ngStyle]="sizeStyle">
        <div style="height: 100%; width: 100%;">
            <gc-spread-sheets id="spreadContainer" #spreadContainer [hostStyle]="hostStyle"
                (workbookInitialized)="initSpread($event)">
                <gc-worksheet>
                </gc-worksheet>
            </gc-spread-sheets>
        </div>
    </div>
</resize-border>