import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EvaluationStep2ModalComponent } from './evaluation-step2-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule, ButtonsModule, WavesModule, MDBBootstrapModulesPro, CheckboxModule } from 'ng-uikit-pro-standard';
import { PipesModule } from 'src/app/_clusters/pipe/pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileUploadModule } from 'ng2-file-upload';
import { Cloudinary as CloudinaryCore } from 'cloudinary-core';
import { CloudinaryConfiguration, CloudinaryModule } from '@cloudinary/angular-5.x';

export const cloudinary = {
  Cloudinary: CloudinaryCore
};
export const config: CloudinaryConfiguration = { cloud_name: 'duthqxswg' };

@NgModule({
  declarations: [EvaluationStep2ModalComponent],
  imports: [
    CommonModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    ButtonsModule,
    WavesModule,
    NgSelectModule,
    CloudinaryModule.forRoot(cloudinary, config),
    FileUploadModule,
    CheckboxModule,
    MDBBootstrapModulesPro.forRoot()
  ],

  exports: [EvaluationStep2ModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class EvaluationStep2ModalModule { }
