import { Component, OnInit } from '@angular/core';
import { MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { LogsModalComponent } from 'projects/cpa/src/app/pages/modal-pages/logs-modal/logs-modal.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  modalRef: MDBModalRef;
  // version = 'V457';
  constructor(private modalService: MDBModalService) { }

  ngOnInit(): void { window.scroll({top:0,left:0,behavior:"smooth"});
  }
  openLogModal() {
    this.modalRef = this.modalService.show(LogsModalComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: 'modal-lg modal-dialog-scrollable',
      containerClass: 'right',
      animated: true,
    });
  }

  // testReload(){
  //   window.location.reload();
  // }
}
