import { Component, OnInit, Input } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { LoadStudentAction } from 'src/app/_store/actions/student.action';
import { LmsFeedbackService } from 'src/app/_clusters/services/lms-feedback.service';
import _ from 'underscore';
import { UntypedFormGroup, UntypedFormBuilder, FormControl, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  studentDetails: Observable<Array<any>>;
  userData: any = {};
  categoryDropdown = [];
  categoryQuestionList = [];
  selectedCategory :any;
  selectedCategoryName = '';
  trainerDropdown = [];
  selectedTrainer :any;
  sendStarRating:any = {};
  sendTrainerRating:any ={};
  sendQuestionRating:any ={};
  trainerName : any = {};
  teamName = '';
  feedbackMsg = '';
  msgForTrainer = '';
  courseList = [{"id":"cpa","value":"CPA"},{"id":"cma","value":"CMA"}]
  selectedCourse : any;
  subjectList : any;
  selectedSubject : any;
  classModeList = [{"id":"online","value":"Online"},{"id":"offline","value":"Offline"}]
  selectedClassMode : any;
  showCategoryOptions = false;
  feedbackForm: UntypedFormGroup;
  errorMSG = '';
  generalFeedback = [];
  trainerFeedback = [];
  questionFeedback= [];
  subquestionList = [];
  showThankYou = false;
  FeedbackUrl =[];

  bannerImage ="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1457119/feedback-form-template.svg"

  constructor(
    private fb: UntypedFormBuilder,
    public modalRef: MDBModalRef,
    private feedbackService: LmsFeedbackService,
    private store: Store<any>,

    ) { }

  ngOnInit(): void { window.scroll({top:0,left:0,behavior:"smooth"});
    this.getStudentDetails();
    this.getCategoryDropdown();
    this.getTrainerDropdown();
  }

  multipleFeedback(){
    if(this.selectedCategory == 4 || this.selectedCategory == 8){
      this.generalFeedback[0] = {
        'category_id' : this.selectedCategory,
        'team_name'   : '',
        'feedback'    : ''
      }
    }

    if(this.selectedCategory == 3 || this.selectedCategory == 7){
      this.trainerFeedback[0]= {
          'category_id' : this.selectedCategory,
          // 'question   ' : {},
          'trainer_id'  : [],
          'course'      : this.selectedCourse,
          'subject_id'  : [],
          'class_mode'  : [],
          'message'     : '',
      }
  }

  if(this.selectedCategory !== 3 && this.selectedCategory !== 4 && this.selectedCategory !== 7 && this.selectedCategory !== 8){
    this.questionFeedback[0] = {
      'category_id' : this.selectedCategory,
      'question_id' : [],
      'question_name' :'',
      'subquestion_id':[],
      'rating'      : 0
    }
  }
  }

  sendFeedBack() {
    this.getFeedbackArray();
    let feedbackArray = [];

    if(this.selectedCategory == 3 || this.selectedCategory == 7){
      feedbackArray = Object.values(this.sendTrainerRating);

    }else if(this.selectedCategory == 4 || this.selectedCategory == 8){
      feedbackArray = Object.values(this.sendStarRating);
    }else{
      feedbackArray = Object.values(this.sendStarRating);

      // feedbackArray = Object.values(this.sendQuestionRating);
    }
    // console.log(feedbackArray);return;

    if(!_.isUndefined(this.errorMSG) && this.errorMSG == ''){
        this.feedbackService.updateStudentFeedback({feedback:feedbackArray}).subscribe((res:any)=>{
    // console.log('res',res)
          if(res && res.status){
            this.onBack();
            this.showThankYou = true;
            // Swal.fire({
            //   icon: "success",
            //   title: "Thank You.",
            //   text: "Feedback Saved Successfully.",
            //   allowOutsideClick: false,
            //   showConfirmButton: false,
            //   timer: 3000
            // })
          }
        });
    }else{
      console.log(this.errorMSG)
      this.showThankYou = false;

      Swal.fire({
        icon: "error",
        text: this.errorMSG,
        allowOutsideClick: false,
        showConfirmButton: false,
        timer: 3000
      })
      this.errorMSG = '';
    }
  }

  getStudentDetails(){
     this.store.dispatch(new LoadStudentAction());
     this.studentDetails = this.store.select((store: any) => store.student?.student_details);

     this.studentDetails.subscribe((data: any) => {
       if (data) {
         this.userData = data;
        //  console.log(this.userData)
       }
     });
  }

  getCategoryDropdown(){
    this.feedbackService.getCategoryDropdown({status:[1]}).subscribe((res:any)=>{

      if(res && res.status){
        this.categoryDropdown = res.data;
        this.selectedCourse   = res.data[0].course;

        if(this.selectedCourse == 'cpa'){
          this.subjectList = [{"id":101,"value":"AUD"},{"id":102,"value":"BEC"},{"id":103,"value":"FAR"},{"id":104,"value":"REG"},{"id":107,"value":"AICPA"}]
        }else{
          this.subjectList = [{"id":105,"value":"Part - 1"},{"id":106,"value":"Part - 2"},{"id":107,"value":"AICPA"}]
        }
      }
    });
  }

  getTrainerDropdown(){
    this.feedbackService.getTrainerDropdown({status:[1]}).subscribe((res:any)=>{

      if(res && res.status){
        this.trainerDropdown = res.data;

        _.each(this.trainerDropdown,val=>{
          if(_.isUndefined(this.trainerName[val.trainer_id])){
            this.trainerName[val.trainer_id]={}
            this.trainerName[val.trainer_id]['name']=val.name;
          }
        })
      }
    });
  }

  getFeedbackArray(){
    console.log(this.selectedCategory)
    if(this.selectedCategory == 3 || this.selectedCategory == 7){
      this.sendStarRating = {};

      let i = 0;
      _.each(this.trainerFeedback,(val,key)=>{
            val.trainer_id = (val.trainer_id) ? val.trainer_id:''
            val.course     = (val.course) ? val.course:''
            val.subject_id = (val.subject_id) ? val.subject_id:''
            val.class_mode = (val.class_mode) ? val.class_mode:''

          if(
            val.trainer_id == '' || val.course == '' || val.subject_id == '' || val.class_mode == '' || val.message == ''
          ){
              this.errorMSG = "Please complete all the required fields for the chosen trainer."
          }
            _.each(this.categoryQuestionList,(value,k)=>{

              this.sendTrainerRating[i]={};
              this.sendTrainerRating[i]['category_id'] = val.category_id;
              this.sendTrainerRating[i]['question_id'] = value.question_id;
              this.sendTrainerRating[i]['rating']      = (!_.isUndefined(this.trainerFeedback[key]) && !_.isUndefined(this.trainerFeedback[key]['question']) && !_.isUndefined(this.trainerFeedback[key]['question'][value.question_id])) ? this.trainerFeedback[key]['question'][value.question_id] : 0;
              this.sendTrainerRating[i]['trainer_id']  = (!_.isUndefined(val.trainer_id)) ? val.trainer_id : '';
              this.sendTrainerRating[i]['course']      = (!_.isUndefined(this.selectedCourse )) ? this.selectedCourse : '';
              this.sendTrainerRating[i]['subject_id']  = (!_.isUndefined(val.subject_id)) ? val.subject_id : '';
              this.sendTrainerRating[i]['class_mode']  = (!_.isUndefined(val.class_mode)) ? val.class_mode : '';
              this.sendTrainerRating[i]['message']     = (!_.isUndefined(val.message))? val.message :'';


              if(this.sendTrainerRating[i]['rating'] == 0){
                this.errorMSG = "Please complete all the required fields for the chosen trainer."
              }
              i++;

            });


      });


    }else if(this.selectedCategory == 4 || this.selectedCategory == 8){
      if(!_.isEmpty(this.generalFeedback)){
        this.sendStarRating = {};

        _.each(this.generalFeedback,(val,k) =>{

          if(val.team_name == '' || val.feedback == ''){
            this.errorMSG = "Please complete all the required fields for the chosen process/team/associate."
          }
          this.sendStarRating[k]={};
          this.sendStarRating[k]['category_id'] = this.selectedCategory;
          this.sendStarRating[k]['team_name']   = val.team_name;
          this.sendStarRating[k]['feedback']    = val.feedback;

        })
      }
    }else{

      if(!_.isEmpty(this.questionFeedback)){
        this.sendStarRating = {};

        _.each(this.questionFeedback,(val,k) =>{
// console.log('val.subquestion_id',val.subquestion_id)
          if( val.question_id == ''  || (val.rating <= 0 && ![6,9,31,34].includes(val.question_id)) || (!_.isEmpty(this.subquestionList[k]) && _.isEmpty(val.subquestion_id)) ){
            this.errorMSG = "Please complete all the required fields for the chosen category."
          }
          this.sendStarRating[k]={};
          this.sendStarRating[k]['category_id']    = this.selectedCategory;
          this.sendStarRating[k]['question_id']    = val.question_id;
          this.sendStarRating[k]['question_name']  = val.question_name;
          this.sendStarRating[k]['subquestion_id'] = val.subquestion_id;
          this.sendStarRating[k]['rating']         = val.rating;

        })
      }
      // if(!_.isEmpty(this.categoryQuestionList)){
      //   this.sendQuestionRating = {};

      //     _.each(this.categoryQuestionList,val=>{
      //       if(!_.isUndefined(this.sendStarRating[val.question_id]) && this.sendStarRating[val.question_id]['rating'] !== 0){
      //         if(_.isUndefined(this.sendQuestionRating[val.question_id])){
      //           this.sendQuestionRating[val.question_id] = {};
      //           this.sendQuestionRating[val.question_id]['category_id'] = this.sendStarRating[val.question_id]['category_id'];
      //           this.sendQuestionRating[val.question_id]['question_id'] = this.sendStarRating[val.question_id]['question_id'];
      //           this.sendQuestionRating[val.question_id]['rating']      = this.sendStarRating[val.question_id]['rating'];
      //         }else{
      //           this.sendQuestionRating[val.question_id]['category_id'] = this.sendStarRating[val.question_id]['category_id'];
      //           this.sendQuestionRating[val.question_id]['question_id'] = this.sendStarRating[val.question_id]['question_id'];
      //           this.sendQuestionRating[val.question_id]['rating']      = this.sendStarRating[val.question_id]['rating'];

      //         }
      //       }
      //   });
      // }
    }
  }

  getCategoryQuestionList(){
    this.feedbackService.getCategoryQuestionList({category_id:this.selectedCategory,status:[1]}).subscribe((res:any)=>{

      if(res && res.status){
        this.categoryQuestionList = res.data;

    //     if(!_.isEmpty(this.categoryQuestionList)){

    //       _.each(this.categoryQuestionList,val=>{

    //       if([3,4].indexOf(this.selectedCategory) == -1){
    //         if(_.isUndefined(this.sendStarRating[val.question_id])){
    //           this.sendStarRating[val.question_id]={};
    //           this.sendStarRating[val.question_id]['category_id'] = val.category_id;
    //           this.sendStarRating[val.question_id]['question_id'] = val.question_id;
    //           // this.sendStarRating[val.question_id]['trainer_id']  = "";
    //           this.sendStarRating[val.question_id]['rating']      = 0;
    //           // this.sendStarRating[val.question_id]['course']      = "";
    //           // this.sendStarRating[val.question_id]['subject_id']  = "";
    //           // this.sendStarRating[val.question_id]['class_mode']  = "";
    //           // this.sendStarRating[val.question_id]['message']     = "";
    //         }else{
    //           this.sendStarRating[val.question_id]['category_id'] = val.category_id;
    //           this.sendStarRating[val.question_id]['question_id'] = val.question_id;
    //           // this.sendStarRating[val.question_id]['trainer_id']  = "";
    //           this.sendStarRating[val.question_id]['rating']      = 0;
    //           // this.sendStarRating[val.question_id]['course']      = "";
    //           // this.sendStarRating[val.question_id]['subject_id']  = "";
    //           // this.sendStarRating[val.question_id]['class_mode']  = "";
    //           // this.sendStarRating[val.question_id]['message']     = "";
    //         }
    //       }

    //       })

    //     }
      }
    });
  }

  onCategoryChange(e,data){
    this.selectedCategory = e.target.defaultValue;
    this.selectedCategoryName = data.category
    this.multipleFeedback();
    // console.log(this.selectedCategory)

  }

  feedbackData(rate,data){
// console.log(rate)
    this.sendStarRating[data.question_id]['rating'] = rate;
    console.log(data,Object.values(this.sendStarRating));
  }

  multifeedbackData(rate,data,i){
    // console.log('i',i)
    if(_.isUndefined(this.trainerFeedback[i])){
      this.trainerFeedback[i] = {};
    }

    if(_.isUndefined(this.trainerFeedback[i]['question'])){
      this.trainerFeedback[i]['question']={}
      this.trainerFeedback[i]['question'][data.question_id] = rate
    } else{
      this.trainerFeedback[i]['question'][data.question_id]=rate;
    }
    // this.sendStarRating[data.question_id]['rating'] = rate;
    // console.log(this.trainerFeedback);
  }

  getQuestionRatingData(rate,data,i){
    this.questionFeedback[i]['rating']         = rate ;
    this.questionFeedback[i]['subquestion_id'] = [] ;
    this.subquestionList[i] = [];
    let payload = {
      "category_id" : this.questionFeedback[i]['category_id'],
      "question_id" : this.questionFeedback[i]['question_id'],
      "rating"      : this.questionFeedback[i]['rating']
    };

    this.getSubQuestions(payload,i);
  }

  onNext(){
    if(this.selectedCategory){
      this.getCategoryQuestionList();
      this.showCategoryOptions = true;
    }else{
      Swal.fire({
        icon: "info",
        text: "Please select category.",
        allowOutsideClick: false,
        showConfirmButton: false,
        timer: 3000
      })
    }


  }

  onBack(){
    this.showCategoryOptions  = false;
    this.showThankYou = false;
    this.selectedCategory     = 0;
    this.selectedCategoryName = '';
    this.categoryQuestionList = [];
    this.generalFeedback = [];
    this.trainerFeedback = [];
    this.questionFeedback= [];
  }

  addGeneralFeedback(){

    this.getFeedbackArray();
    if(!_.isUndefined(this.errorMSG) && this.errorMSG == ''){

      let gf_index = this.generalFeedback.length;

        if(_.isUndefined(this.generalFeedback[gf_index])){
          this.generalFeedback[gf_index]={};
          this.generalFeedback[gf_index]['category_id'] = this.selectedCategory;
          this.generalFeedback[gf_index]['team_name']   = '';
          this.generalFeedback[gf_index]['feedback']    = '';
        }
    setTimeout(() => document.getElementById('scrollgfid_'+ gf_index).scrollIntoView({ block: 'end', behavior: 'smooth' }), 500);

    }else{
      console.log(this.errorMSG)
      Swal.fire({
        icon: "error",
        text: 'Please complete all the required fields for the chosen process/team/associate before adding feedback for another process/team/associate !',
        allowOutsideClick: false,
        showConfirmButton: false,
        timer: 3000
      })
      this.errorMSG = '';
    }

    _.each(this.generalFeedback , (val,key) =>{
      if((this.generalFeedback.length - 1) == key){
        document.getElementById('gfclose_'+key).style.display = 'block';
      }else{
        document.getElementById('gfclose_'+key).style.display = 'none';
      }
    })
  }

  deleteGeneralFeedback(gf_index){

    if (gf_index > -1 && this.generalFeedback.length >1) {
      this.generalFeedback.splice(gf_index, 1);
    }
  }

  addTrainerFeedback(){

    this.getFeedbackArray();

    if(!_.isUndefined(this.errorMSG) && this.errorMSG == ''){

      let gf_index = this.trainerFeedback.length;

        if(_.isUndefined(this.trainerFeedback[gf_index])){
          this.trainerFeedback[gf_index]={};
          this.trainerFeedback[gf_index]['category_id'] = this.selectedCategory;
          // this.trainerFeedback[gf_index]['question']    = {};
          this.trainerFeedback[gf_index]['trainer_id']  = [];
          this.trainerFeedback[gf_index]['course']      = this.selectedCourse ;
          this.trainerFeedback[gf_index]['subject_id']  = [];
          this.trainerFeedback[gf_index]['class_mode']  = [];
          this.trainerFeedback[gf_index]['message']     = '';

        }
        setTimeout(() => document.getElementById('scrollid_'+ gf_index).scrollIntoView({ block: 'end', behavior: 'smooth' }), 500);

    }else{
      console.log(this.errorMSG)
      Swal.fire({
        icon: "error",
        text: 'Please complete all the required fields for the chosen trainer before adding feedback for another trainer!',
        allowOutsideClick: false,
        showConfirmButton: false,
        timer: 3000
      })
      this.errorMSG = '';
    }

    _.each(this.trainerFeedback , (val,key) =>{
      if((this.trainerFeedback.length - 1) == key){
        document.getElementById('tfclose_'+key).style.display = 'block';
      }else{
        document.getElementById('tfclose_'+key).style.display = 'none';
      }
    });

  }

  deleteTrainerFeedback(gf_index){

    if (gf_index > -1 && this.trainerFeedback.length >1) {
      this.trainerFeedback.splice(gf_index, 1);
    }
  }

  addQuestionFeedback(){

    this.getFeedbackArray();

    if(!_.isUndefined(this.errorMSG) && this.errorMSG == ''){

      let que_index = this.questionFeedback.length;

        if(_.isUndefined(this.questionFeedback[que_index])){
          this.questionFeedback[que_index]={};
          this.questionFeedback[que_index]['category_id'] = this.selectedCategory;
          this.questionFeedback[que_index]['question_id'] = [];
          this.questionFeedback[que_index]['question_name']  = '';
          this.questionFeedback[que_index]['subquestion_id'] = [];
          this.questionFeedback[que_index]['rating']      = 0 ;

        }
        setTimeout(() => document.getElementById('scrollQueid_'+ que_index).scrollIntoView({ block: 'end', behavior: 'smooth' }), 500);

    }else{
      console.log(this.errorMSG)
      Swal.fire({
        icon: "error",
        text: 'Please complete all the required fields for the chosen category before adding feedback for another category!',
        allowOutsideClick: false,
        showConfirmButton: false,
        timer: 3000
      })
      this.errorMSG = '';
    }

    _.each(this.questionFeedback , (val,key) =>{
      if((this.questionFeedback.length - 1) == key){
        document.getElementById('question_close_'+key).style.display = 'block';
      }else{
        document.getElementById('question_close_'+key).style.display = 'none';
      }
    });

  }

  deleteQuestionFeedback(que_index){

    if (que_index > -1 && this.questionFeedback.length >1) {
      this.questionFeedback.splice(que_index, 1);
    }
  }

  onGeneralFeedbackClick(i){
    let Display = document.getElementById('gfclose_'+i).style.display;

    if(Display == 'none'){
      document.getElementById('gfclose_'+i).style.display = 'block';
    }else{
      document.getElementById('gfclose_'+i).style.display = 'none';
    }

  }

  onTrainerFeedbackClick(i){
    let Display = document.getElementById('tfclose_'+i).style.display;

    if(Display == 'none'){
      document.getElementById('tfclose_'+i).style.display = 'block';
    }else{
      document.getElementById('tfclose_'+i).style.display = 'none';
    }

  }

  onQuestionFeedbackClick(i){
    let Display = document.getElementById('question_close_'+i).style.display;

    if(Display == 'none'){
      document.getElementById('question_close_'+i).style.display = 'block';
    }else{
      document.getElementById('question_close_'+i).style.display = 'none';
    }

  }

  onQuestionChange(e,i){
    console.log(e)
    this.questionFeedback[i]['question_id']= e.question_id;
    this.questionFeedback[i]['question_name']= e.question;
    this.questionFeedback[i]['rating']       = 0 ;

    this.FeedbackUrl[i] = {};

    if(e.question_id == 6){
      this.FeedbackUrl[i]['feedback_url'] = "lms/mileage/attendance"
    }else if(e.question_id == 9){
      this.FeedbackUrl[i]['feedback_url'] = "lms/mileage/appointments/previous"
    }else if(e.question_id == 31){
      this.FeedbackUrl[i]['feedback_url'] = "cma/lms/attendance"
    }else if(e.question_id == 34){
      this.FeedbackUrl[i]['feedback_url'] = "cma/lms/appointments/previous"
    }else{
      this.FeedbackUrl[i]['feedback_url'] = "javascript:;"
    }
  }

  getSubQuestions(payload,i){

    console.log(payload,this.subquestionList)
    this.feedbackService.getSubquestionOnRating(payload).subscribe((res:any)=>{
            if(res && res.status){
              this.subquestionList[i]  = res.data ;

            }
          });
  }

  getSelectedSubQuestions(e,row,i){

    if (e.target.checked) {
      this.questionFeedback[i]['subquestion_id'].push(row.sub_question_id)
    }else{

      let removedIndex = this.questionFeedback[i]['subquestion_id'].indexOf(row.sub_question_id);
      if(removedIndex > -1){
        this.questionFeedback[i]['subquestion_id'].splice(removedIndex, 1);
      }
    }
  }

}
