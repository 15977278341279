import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class SubjectService {
  Url = `${environment.apiUrl}`;
  audSubjectId = 'c53f9b7df8fbb22dc12d0e859a5c9734';
  besSubjectId = '53625d62402e139ccc1e441dea96b90c';
  farSubjectId = 'a1a8cddccb57f50303507da5087ed062';
  regSubjectId = '5fdf99246bcbd70b7fdb6b6b600e6d35';
  ebook: BehaviorSubject<any> = new BehaviorSubject([]);
  webinarVideoList: Subject<any> = new Subject();
  ebookSubtopicName: BehaviorSubject<any> = new BehaviorSubject([]);
  ebookMetadata: BehaviorSubject<any> = new BehaviorSubject([]);
  Breadcrumbs: BehaviorSubject<any> = new BehaviorSubject([]);
  constructor(private http: HttpClient) { }
  getMainTopic(id) {
    return this.http.post(this.Url + 'subject/getMainTopic', {
      subject_id: id,
    });
  }
  getSubTopic(subject_id, maintopic_id) {
    return this.http.post(this.Url + 'subject/getSubTopic', {
      maintopic_id,
      subject_id,
    });
  }
  gettopicwisereport(subject_id, maintopic_id, aicpaInter?) {
    let aicpa_inter = aicpaInter;
    if (aicpaInter == 1) {
      aicpa_inter = '2';
    } else {
      aicpa_inter = '';
    }

    return this.http.post(this.Url + 'gettopicwisereport', {
      maintopic_id,
      subject_id,
      aicpa_inter,
    });
  }

  gettopicreport(subject_id) {
    return this.http.post(this.Url + 'dashboard', {
      request_from: 'web',
      subject_id,
    });
  }

  gettopicreportAicpaInter(payload) {
    return this.http.post(this.Url + 'dashboard', payload);
  }
  changeQuestionsMode(subject_id, questions_mode) {
    return this.http.post(this.Url + 'changeQuestionsMode', {
      subject_id,
      questions_mode,
    });
  }
  getQuestionIds(question_id) {
    return this.http.post(this.Url + 'getQuestionIds', { question_id });
  }
  getQuestionDeatilsIds(question_id, is_attempt) {
    return this.http.post(this.Url + 'getQuestionDetails', {
      question_id,
      is_attempt,
      created_from: '1',
    });
  }
  simQuestionView(subject_id, int_maintopic_id, question_id, attempt_r_view) {
    return this.http.post(this.Url + 'simQuestionView', {
      subject_id,
      int_maintopic_id,
      question_id,
      attempt_r_view,
    });
  }
  getSubjectList(maintopic_id, subject_id) {
    return this.http.post(this.Url + 'getsubTopicsList', {
      maintopic_id,
      subject_id,
    });
  }
  private handleError(error: Response) {
    return Observable.throw(error.statusText);
  }
  activateVideos(subject_id, activation_code) {
    return this.http.post(this.Url + 'ActivateVideos', {
      subject_id,
      activation_code,
    });
  }
  activateMcq(subject_id, activation_code) {
    return this.http.post(this.Url + 'ActivateMcq', {
      subject_id,
      activation_code,
    });
  }
  ebooks(subject_id, subtopic_id, old_year) {
    if (subject_id && subtopic_id && subject_id != "undefined" && subtopic_id  != "undefined") {
      const params =
        old_year == 1
          ? { subject_id, subtopic_id, old_year }
          : { subject_id, subtopic_id };
      this.http.post(this.Url + 'geteBooks', params).subscribe((res: any) => {
        // console.log('res', res)
        if (res && res.data.length) {
          this.ebook.next(res.data);
          this.ebookSubtopicName.next(res.data[0].subtopic_name);
          this.ebookMetadata.next(res.metadata[0]);
        } else {
          Swal.fire({
            icon: 'error',
            text: ' Coming Soon!',
            allowOutsideClick: false,
          }).then((res) => {
            window.close();
          });
        }
      });
    }
    else {
      Swal.fire({
        icon: "info",
        title: "Fetching Information..!",
        allowOutsideClick: false,
        showConfirmButton: false,
        timer: 50000,
        timerProgressBar: true
      })
    }
    // console.log('year', { subject_id, subtopic_id, old_year })
  }
  getWebinarVideos(subject_id, maintopic_id?) {
    return this.http.post(this.Url + 'getWebinarVideos', {
      subject_id,
      maintopic_id,
    });
  }
  getWebinarVideos1(subject_id, batch_id?) {
    return this.http.post(this.Url + 'getWebinarVideos', {
      subject_id,
      batch_id,
    });
  }
  getSubjectDetails(subjectId) {
    switch (subjectId) {
      case 'c53f9b7df8fbb22dc12d0e859a5c9734':
        return {
          subjectName: 'Auditing & Attestation',
          logo: 'fa-search-dollar',
          shortName: 'AUD',
        };
        break;
      case '53625d62402e139ccc1e441dea96b90c':
        return {
          subjectName: 'Business Environment & Concepts',
          logo: 'fa-chart-line',
          shortName: 'BEC',
        };
        break;
      case 'a1a8cddccb57f50303507da5087ed062':
        return {
          subjectName: 'Financial Accounting & Reporting',
          logo: 'fa-university',
          shortName: 'FAR',
        };
        break;
      case '5fdf99246bcbd70b7fdb6b6b600e6d35':
        return {
          subjectName: 'Regulation',
          logo: 'fa-gavel',
          shortName: 'REG',
        };
        case 'e3cabe6023121c56d1ffa9a8b12e100a':
        return {
          subjectName: 'Business Analysis & Reporting',
          logo: 'fa-chart-line',
          shortName: 'BAR',
        };
        case '9eeb9a6ec1f23e922bc44fef3efbb772':
        return {
          subjectName: 'Tax Compliance & Planning',
          logo: 'fa-search-dollar',
          shortName: 'TCP',
        };
        case 'b082ef78d9ebe9fc85f86747040746a5':
        return {
          subjectName: 'Information Systems and Control',
          logo: 'fa-folder',
          shortName: 'ISC',
        };
        break;
      default:
        break;
    }
  }
  practiceMocktest(subject_id?) {
    return this.http.post(this.Url + 'create/PracticeMocktest', { subject_id });
  }
  getPreviousScore(subject_id, exam_code) {
    return this.http.post(this.Url + 'getPreviousPracticeTestResults', {
      subject_id,
      exam_code,
    });
  }

  saveEbookNotes(params) {
    return this.http.post(this.Url + 'saveEbookNotes', { ...params });
  }
  update_aud_status(params) {
    return this.http.post(this.Url + 'update_aud_status', { ...params });
  }

  getJobsimQuestionView(questionId) {
    return this.http.post(this.Url + 'jobSimQuestionView', questionId);
  }

  getAttemptedQuestionList() {
    return this.http.post(this.Url + 'getAttemptedQuestionList', {});
  }

  getAdditionalUpdates(payload: any) {
    return this.http.post(this.Url + 'getAdditionalUpdates', payload);
  }

  insertAdditionalUpdates(payload: any) {
    return this.http.post(this.Url + 'insertAdditionalUpdates', payload);
  }

  getTutorials(payload: any) {
    return this.http.post(this.Url + 'getTutorials', payload);
  }

  insertTutorials(payload: any) {
    return this.http.post(this.Url + 'inserttutorials', payload);
  }
  updatepopread(int_notification_id) {
    return this.http.post(this.Url + 'contentnotificationread', {
      int_notification_id,
    });
  }

  getFinalMiniMockResultList(data) {
    return this.http.post(this.Url + 'getFinalMiniMockResultList', data);
  }

  updateAdditionalUpdatesStatus(data) {
    return this.http.post(this.Url + 'updateAdditionalUpdatesStatus', data);
  }

  updateZoomWebinarStatus(data) {
    return this.http.post(this.Url + 'updateZoomWebinarStatus', data);
  }
  getPathwayList() {
    return this.http.post(this.Url + 'getPathwayList', {});
  }
  getPathwayCourseList() {
    return this.http.post(this.Url + 'getPathwayCourseList', {});
  }
  // getPathwayDetails() {
  //   return this.http.post(this.Url + 'getPathwayDetails', {});
  // }

  getPathwayDetails() {
    return this.http.post(this.Url + 'getUserPathwayDetails', {});
  }

  updatePathway(data) {
    return this.http.post(this.Url + 'updatePathway', data);
  }
  getColorCode(val) {
    if (val === 'c53f9b7df8fbb22dc12d0e859a5c9734') {
      // return '#95b548';
      return '#8CA9A2'
    }
    if (val === '53625d62402e139ccc1e441dea96b90c') {
      // return '#facd45';
      return '#F6BD60';
    }
    if (val === 'a1a8cddccb57f50303507da5087ed062') {
      // return '#42b2ec';
      return '#457B9D';
    }
    if (val === '5fdf99246bcbd70b7fdb6b6b600e6d35') {
      // return '#e74549';
      return '#E63946';
    }
  }

  changeSubjectReference(param) {
    return this.http.post(this.Url + 'changeSubjectReference', param)
  }

}
