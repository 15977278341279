import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import * as GC from "@grapecity/spread-sheets";
import "@grapecity/spread-sheets-print";
import "@grapecity/spread-sheets-shapes";
import "@grapecity/spread-sheets-slicers";
import "@grapecity/spread-sheets-tablesheet";
import "@grapecity/spread-sheets-io";
import "@grapecity/spread-sheets-designer-resources-en";
import "@grapecity/spread-sheets-designer";
import * as GCDesigner from "@grapecity/spread-sheets-designer";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { McqsService } from "../_clusters/services/mcqs.service";
import { ActivatedRoute } from "@angular/router";
import * as ExcelIO from "@grapecity/spread-excelio";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";

const licenceKey =
  "Miles Education,*.mileseducation.com,735127231874391#B1udWcsVVbyQjMONWWohTVvQmcOVGVCR4bvFXerlzbER6Kk5GUlRHbDB5dqpGenRFM5EFN8k4bnB5cI3SS5NVQtFnMjRUdBFFRuVEW5oENQFHall7UwoGc9A5K9kWd8R7bxx4aBt4b5cTbnpUZ4J7Q4hXOotSOodlNi94VuNXMqR4TXJWQBl5aPFTR9pmM9Rma9dHSr94TIJ7Rp3Ub7kWb5I5N7klZyZ6STNTS5Y4TPpnQORndRtkSGNESOVUaChkWmhWeH3UMzp7UzglcWZTQKxUWMdHW5JkV6p7bVFVSlFla7EXa8VWRFN7ZYVkI0IyUiwiI7ADRyMDR8IjI0ICSiwiNyADN9gDO9ITM0IicfJye#4Xfd5nINBVO7IiOiMkIsIiNx8idg86bkRWQtIXZudWazVGRtMlSkFWZyB7UiojIOJyebpjIkJHUiwiI9IDM5YDMgkDM9AzMyAjMiojI4J7QiwiIt36Yu86bpRXYjVHZlNXZslWbuoiI0IyctRkIsIibvlGdhNWdkVEIzVGbp5kI0ISYONkIsISM9MDN7gTMzIzNyETNzcjI0ICZJJye0ICRiwiI34TQvUGdtdWSpNUdUJ5LXpGM0tEMhhXMsRUd8oHWvQXRlBzYNJENGlnSzQWY9d4RvQDWt9GNMFFdRVmYG34QHZmRtt4Mwg7KCFVSEZ7QyMGV456NB96M5c7NixUWBlTURBHZrcnNrMEcg7ke";
GC.Spread.Sheets.LicenseKey =
  "*.mileseducation.com,168388884292425#B1nLt5NuNHNx4GdHR4aSxkUphHZZh7VykVZatyN98UZ7hmWIx4RzEzTUNFWvQWSYlFaRtSchdDRzd4MlB5QrYVbxNjU7ZGODFFUuF6cMpVTwkFTJNUSYxUVHp4YKNzL7VXeIlVUu3ERER4LudDO8VWNzhjW09Gc4lWSoFmMxlVSGJzZ7I4Q9omYLZXNsNjUUlWeQZzN7E5cTlzNuVHWBtSQnJzdKV6KaJ4N6JmcUhDZ7V7ZEZkQ7dWTihnWP5mZpJzUOpkUulWYjN6VnJmTwJzYzpUTT9UOCRldRBjRxsEeMJnZvhUNWhTU0dFdol6RYhWcaRXWiojITJCLiMjN6kDM6UjNiojIIJCLzIzM5czMxAjM0IicfJye&Qf35VficFVDplI0IyQiwiI6EjL6ByUKBCZhVmcwNlI0IiTis7W0ICZyBlIsISMyUTM5ADI4ETOwMjMwIjI0ICdyNkIsISbvNmLu3Wa4F6Y5RWZzVGbp5mLqIiOiMXbEJCLi86bpRXYjVHZFByclxWaNJiOiEmTDJCLiUjM4ITOyQDO8gDOzgjNxIiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TP7lHVqhEZwNWOYlDe6olVrc7U7d4T6kjc5U5TxZUZyU5b5wWeiRnd4BTTwMUbuN7SzZVNllDRRh7Uql6QTJjN93kMwZHO6ZkM5NzLzJ7L9U5TTdTTGdEMz4WRnd4bkFH22o0";
  (ExcelIO as any).LicenseKey = "*.mileseducation.com,168388884292425#B1nLt5NuNHNx4GdHR4aSxkUphHZZh7VykVZatyN98UZ7hmWIx4RzEzTUNFWvQWSYlFaRtSchdDRzd4MlB5QrYVbxNjU7ZGODFFUuF6cMpVTwkFTJNUSYxUVHp4YKNzL7VXeIlVUu3ERER4LudDO8VWNzhjW09Gc4lWSoFmMxlVSGJzZ7I4Q9omYLZXNsNjUUlWeQZzN7E5cTlzNuVHWBtSQnJzdKV6KaJ4N6JmcUhDZ7V7ZEZkQ7dWTihnWP5mZpJzUOpkUulWYjN6VnJmTwJzYzpUTT9UOCRldRBjRxsEeMJnZvhUNWhTU0dFdol6RYhWcaRXWiojITJCLiMjN6kDM6UjNiojIIJCLzIzM5czMxAjM0IicfJye&Qf35VficFVDplI0IyQiwiI6EjL6ByUKBCZhVmcwNlI0IiTis7W0ICZyBlIsISMyUTM5ADI4ETOwMjMwIjI0ICdyNkIsISbvNmLu3Wa4F6Y5RWZzVGbp5mLqIiOiMXbEJCLi86bpRXYjVHZFByclxWaNJiOiEmTDJCLiUjM4ITOyQDO8gDOzgjNxIiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TP7lHVqhEZwNWOYlDe6olVrc7U7d4T6kjc5U5TxZUZyU5b5wWeiRnd4BTTwMUbuN7SzZVNllDRRh7Uql6QTJjN93kMwZHO6ZkM5NzLzJ7L9U5TTdTTGdEMz4WRnd4bkFH22o0";
(GC.Spread.Sheets as any).Designer.LicenseKey = licenceKey;

var config: any = GCDesigner.Spread.Sheets.Designer.DefaultConfig;
config.fileMenu = "";

config.ribbon[4].buttonGroups.splice(0, 2);

config.ribbon = config.ribbon.filter((data: any) =>
  ["home", "insert", "formulas", "data", "view"].includes(data.id)
);

config.ribbon[1].buttonGroups = config.ribbon[1].buttonGroups.filter(
  (data: any) => {
    if (data.label === "Tables") {
      data.commandGroup.commands = ["insertTable"];
      return true;
    }
    return false;
  }
);
config.ribbon[0].buttonGroups.shift();
config.ribbon[0].buttonGroups.shift();
@Component({
  selector: "app-spread",
  templateUrl: "./spread-designer.component.html",
  styleUrls: ["./spread-designer.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SpreadDesignerComponent implements OnInit {
  @ViewChild("spreadContainer", { static: false }) spreadContainer: ElementRef;
  @Input() session_id: any;
  @Input() testlet_id: any = null;
  @Input() closeModal: any = false;
  @Output() closed: any = new EventEmitter();
  props = {
    config: config,
  };
  spread: GC.Spread.Sheets.Workbook;
  hostStyle = {
    width: "600px",
    height: "300px",
  };
  sheetData: any = [];
  is_update: number = 0;
  sheet: any;
  sizeStyle: any = {
    width: "500px",
    height: "509px",
  };
  constructor(
    private mcqService: McqsService,
    private activeModal:NgbActiveModal
  ) {}
  ngOnInit(): void {
    var param:any ={
      session_id: this.session_id
    }
    if(this.testlet_id){
      param.testlet_id = this.testlet_id
    }
    this.mcqService
      .getSpreadData(param)
      .subscribe((res: any) => {
        if (res && res.status) {
          this.sheetData = res.data[0].sheet_data;
          this.is_update = 1;
          console.log(this.sheet);
          if (this.is_update && this.sheetData) {
            var spread = this.sheet;
            spread.fromJSON(JSON.parse(this.sheetData), {});
          }
        }
      });
      setInterval(() => {
        this.triggerSpreadSheet(false);
      }, 30000);
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log("🚀 ~ file: spread-designer.component.ts:114 ~ SpreadDesignerComponent ~ ngOnChanges ~ changes:", changes)
    // if(changes.closeModal){}
  }
  initDesigner($event: any) {
    let workbook = $event.designer.getWorkbook();
    this.spread = workbook;
  }
  initSpread($event: any) {
    var designer = new GC.Spread.Sheets["Designer"].Designer(
      document.getElementById("spreadContainer")
    );
    designer.setWorkbook($event.spread);
    this.spread = designer.getWorkbook($event.spread);
    // console.log("🚀 ~ file: spread-designer.component.ts:164 ~ SpreadDesignerComponent ~ initSpread ~ this.spread:", this.spread)
    this.sheet = $event.spread;
  }
  triggerSpreadSheet(close:any=true) {
    const spread = this.spread;
    let jsonStr = spread.toJSON();
    var param:any = {
      sheet_data: JSON.stringify(jsonStr),
      session_id: this.session_id,
      is_update: this.is_update,
    };
    if(this.testlet_id){
      param.testlet_id = this.testlet_id
    }
    this.mcqService.saveAndUpdateSpreadData(param).subscribe((res: any) => {
      if (res && res.status) {
        if(close){
          this.closed.emit(true)
          this.activeModal.close();
          // this.modalRef.hide();
        }
      }
    });
  }
  setStyle(event: any) {
    this.sizeStyle = event;
    this.hostStyle = {
      width: `${this.sizeStyle.width}`,
      height: `calc(${this.sizeStyle.height} - 209px)`,
    };
    this.initSpread(this.spreadContainer);
  }
  ngOnDestroy(): void {
    const spread = this.spread;
    let jsonStr = spread.toJSON();
    var param:any = {
      sheet_data: JSON.stringify(jsonStr),
      session_id: this.session_id,
      is_update: this.is_update,
    };
    if(this.testlet_id){
      param.testlet_id = this.testlet_id
    }
    this.mcqService.saveAndUpdateSpreadData(param)
  }
}
