<section class="fixed-top">
  <section class="exam_section">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-6">
        <h1 class="d-none d-md-block">{{subjectName}}</h1>
        <h1 class="d-block d-md-none" style="font-size: 13px;margin-top: 20px;margin-left: -10px;padding-left: 0px;">
          {{subjectName}}</h1>
      </div>
      <div class="col-12 col-md-6 col-lg-6">
        <div class="mcq_header_list">
          <ul>
            <li *ngFor="let tab of wctque;let i=index" [ngClass]="{'active':activePng1(i)}">
              <i [ngClass]="activePng1(i)?'fa fa-map-marker-alt':'fas fa-lock'" aria-hidden="true"></i><br />
              Testlet{{ i+1 }}
            </li>
            <li (click)="click('quitmodal');isSpreadModalOpen?closeSpreadModal():''"><i class="fad fa-lock"></i>Quit</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section class="exam_section2">
    <div class="row">
      <div class="col-6 p-0 pl-0 pl-md-3">
        <h1>
          <i class="fas fa-stopwatch" aria-hidden="true"></i>
          <span class="small ml-2">{{hours>0?hours+':':''}}{{minute
            <=9?0:''}}{{minute}}:{{countdown<=9?0:''}}{{countdown}} </span>
        </h1>
      </div>
      <div class="col-6 p-0 px-0 px-md-3">
        <div class="mcq_header_list2">
          <ul>
            <!-- <li (click)="isCalModal=true;showCal=true"> -->
            <!-- <li (click)="!isSpreadModalOpen?openSpreadSheetModal():''">
              <i class="far fa-file-spreadsheet" aria-hidden="true"></i>
              <span class="d-none d-sm-none d-md-none d-lg-inline-block">SPREAD JS</span>
            </li> -->
            <li *ngIf="this.mode == 'new'" class="spread" (click)="!isSpreadModalOpen?openSpreadSheetModal():''">
              <div class="spreadsheet" mdbTooltip="Spreadsheet" placement="bottom"></div><span mdbTooltip="Spreadsheet" placement="bottom">SPREAD JS</span>
            </li>
            <li *ngIf="this.mode == 'old'" class="spread" (click)="excelModal.show()"><i class="fas fa-file-excel"></i><br /><span>EXCEL</span></li>
            <li (click)="calculatorModal.show()">
              <i class="fad fa-calculator" aria-hidden="true" mdbTooltip="Calculator" placement="bottom"></i>
              <span class="d-none d-sm-none d-md-none d-lg-inline-block" mdbTooltip="Calculator" placement="bottom">Calculator</span>
            </li>
            <li *ngIf="!wctMode" (click)="click('overviewmodal');getQuestionList()">
              <i class="fad fa-list-ol" aria-hidden="true" mdbTooltip="Calculator" placement="bottom"></i>
              <span class="d-none d-sm-none d-md-none d-lg-inline-block" mdbTooltip="Calculator" placement="bottom">Overview</span>
            </li>
            <li *ngIf="!showNext && !showSubmit" (click)="showAnswer()"><i class="fad fa-paper-plane" mdbTooltip="Submit" placement="bottom"></i>
              <span class="d-none d-sm-none d-md-none d-lg-inline-block" mdbTooltip="Submit" placement="bottom">Submit</span>
            </li>
            <li *ngIf="showNext && !showSubmit" (click)="nextQuestion()" mdbTooltip="Next" placement="bottom"><i class="fad fa-paper-plane"></i> Next</li>
            <li *ngIf="showSubmit" (click)="click('submittestmodal');wctMode?'':getQuestionList();isSpreadModalOpen?closeSpreadModal():''"><i
                class="fad fa-paper-plane" mdbTooltip="Submit Test" placement="bottom"></i>
              <span class="d-none d-sm-none d-md-none d-lg-inline-block" mdbTooltip="Submit Test" placement="bottom">Submit Test</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="!wctMode">
    <div class="miles-mcq-progress-bar">
      <div class="progress" style="border-radius: 0;background: #eeeeee!important;height: 7px;">
        <div class="progress-bar bg-danger progress-bar-striped progress-bar-animated" role="progressbar"
          [ngStyle]="{width: examProgressBar+'%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </section>
  <!-- <button mdbBtn mdbWavesEffect type="button" class="transform-none text-center py-2 px-3 text-white aud-grd" style="width: 30px;
  position: absolute;right: 0;top: 380px;" block="true" #aud="bs-mdbPopover" [mdbPopover]="calculator" placement="left"
    triggers="click">
    <span class="fad fa-search-dollar mr-2"></span>
    <span class="menu-name">calc</span>
  </button>
  <ng-template #calculator>
    <div class="modal_content">
      <iframe src="https://factory.mileseducation.com/Calculator/Calculator.html" class="calculator"
        style="overflow-y: hidden; height:300px;width:99% !important;"></iframe>
    </div>
  </ng-template> -->

  <!-- <div style="position: fixed;right: 0px;bottom: 0px;border: 1px solid rgb(0, 0, 0);border-radius: 5px 5px 0px 0px;max-width: 515px;" [ngStyle]="{'width': showCal?'100%':'100px'}" *ngIf="isCalModal">
    <div class="modal-header py-2" style="background: #000;border-bottom: 1px solid;color: #ffff;">
      <span style="position: absolute;right: 35px;" aria-label="minimize" (click)="showCal=!showCal">
        <span *ngIf="showCal" aria-hidden="true">  <i class="fas fa-compress-alt"></i> </span>
        <span *ngIf="!showCal" aria-hidden="true"> <i class="fas fa-expand-alt"></i> </span>
      </span>
      <span style="position: absolute; right: 10px;" aria-label="Close" (click)="isCalModal=false">
        <span aria-hidden="true"><i class="fas fa-times-square"></i></span>
      </span>
      <h6 class="modal-title w-100 font-weight-bold mr-2" style="color: #d74c15;text-shadow: .5px 0px 30px #fff;" id="CalculatorModalLabel"  (click)="showCal=!showCal">
        <i class="fas fa-calculator mr-2"></i>
        <span *ngIf="showCal">Calculator</span>
      </h6>
    </div>
    <div class="modal_content p-0" *ngIf="showCal">
      <span class="small text-center position-absolute" style="margin: 15px 30px;">Loading calculator, please wait...</span>
      <iframe src="https://factory.mileseducation.com/Calculator/Calculator.html" class="calculator"></iframe>
    </div>
  </div> -->

  <!-- Excel SpreadSheet -->
<div mdbModal #excelModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
aria-hidden="true" [config]='{backdrop: false, ignoreBackdropClick: true}' style="pointer-events: none;"  >
<div class="modal-dialog modal-lg modal-dialog-scrollable" role="document" cdkDrag>
  <div class="modal-content modal_overview">
    <div class="modal-header">
      <button type="button" class="close pull-right popup_close" aria-label="Close" (click)="excelModal.hide()">
        <i class="fa fa-times"></i>
      </button>
      <h4 class="modal-title w-100" id="myModalLabel">
        Spreadsheet
      </h4>
    </div>
    <!-- <div style="width: 720px;
    min-height: 0px;
    max-height: none;
    height: 576px;">
      <iframe src="https://lms.mileseducation.com/application/spreadsheet/demo.html"
        style="overflow-y: hidden; height:100%;width:99% !important"></iframe>
    </div> -->
    <embed src="https://lms.mileseducation.com/application/spreadsheet/demo.html" class="w-100" height="500"
      alt="spreadsheet" pluginspage="https://www.adobe.com/products/acrobat/readstep2.html">
  </div>
</div>
</div>

  <!-- Calculator -->
  <div mdbModal #calculatorModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="myBasicModalLabel" aria-hidden="true" [config]='{backdrop: false, ignoreBackdropClick: true}'
    style="pointer-events: none;">
    <div class="modal-content modal_overview" cdkDrag style="width: 530px !important;">
      <div class="modal-header py-2">
        <button type="button" class="close pull-right popup_close" aria-label="Close" (click)="calculatorModal.hide()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
        <h6 class="modal-title w-100 font-weight-bold" id="myModalLabel">
          Calculator
        </h6>
      </div>
      <div class="modal_content">
        <iframe src="https://factory.mileseducation.com/Calculator/Calculator.html" class="calculator"
          style="overflow-y: hidden; height:300px;width:99% !important;"></iframe>
      </div>
    </div>
  </div>
  <!-- <div mdbModal #spreadsheetModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="myBasicModalLabel" aria-hidden="true" [config]='{backdrop: false, ignoreBackdropClick: true}'
    style="pointer-events: none;">
    <div class="modal-content modal_overview" cdkDrag style="width: 800px !important;height: 500px !important;">
      <div class="modal-header py-2">
        <button type="button" class="close pull-right popup_close" aria-label="Close" (click)="spreadsheetModal.hide()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
        <h6 class="modal-title w-100 font-weight-bold" id="myModalLabel">
          Spreadsheet
        </h6>
      </div>
      <div class="modal_content">
        {{props|json}}
        <app-spread-designer></app-spread-designer>
      </div>
    </div>
  </div> -->

  <!-- <div style="position: fixed;right: 0px;bottom: 0px;border: 1px solid rgb(0, 0, 0);border-radius: 5px 5px 0px 0px;width: 100%px;" [ngStyle]="{width: showCal?'515px':'100px'}" *ngIf="isCalModal && isMobile">
    <div class="modal-header py-2" style="background: #000;border-bottom: 1px solid;color: #ffff;">
      <span style="position: absolute;right: 35px;" aria-label="minimize" (click)="showCal=!showCal">
        <span *ngIf="showCal" aria-hidden="true">  <i class="fas fa-compress-alt"></i> </span>
        <span *ngIf="!showCal" aria-hidden="true"> <i class="fas fa-expand-alt"></i> </span>
      </span>
      <span style="position: absolute; right: 10px;" aria-label="Close" (click)="isCalModal=false">
        <span aria-hidden="true"><i class="fas fa-times-square"></i></span>
      </span>
      <h6 class="modal-title w-100 font-weight-bold mr-2" style="color: #d74c15;text-shadow: .5px 0px 30px #fff;" id="CalculatorModalLabel"  (click)="showCal=!showCal">
        <i class="fas fa-calculator mr-2"></i>
        <span *ngIf="showCal">Calculator</span>
      </h6>
    </div>
    <div class="modal_content p-0" *ngIf="showCal">
      <span class="small text-center position-absolute" style="margin: 15px 30px;">Loading calculator, please wait...</span>
      <iframe src="https://factory.mileseducation.com/Calculator/Calculator.html" class="calculator"></iframe>
    </div>
  </div> -->

</section>
<router-outlet></router-outlet>

<!-- Overview Modal -->
<div id="overviewmodal" class="modal-window" style="overflow-y: auto">
  <div class="lg_modal_body">
    <div class="modal_header">
      <h1><i class="fad fa-list-ol"></i> Overview</h1>
      <span title="Close" class="modal-close" (click)="click('overviewmodal')"><i class="fad fa-times"></i></span>
    </div>
    <div class="modal_content" style="border-radius: 0px 0px 5px 5px;">
      <table class="overview_table">
        <tr>
          <th>Question</th>
          <th>**Select a question text to visit that item</th>
        </tr>

        <tr *ngFor="let row of mcqQuestionList;let i= index">
          <td>
            <div class="left_section" (click)="saveFlag(row)"
              [ngClass]="{'active':bookmark[row.txt_question_id],'disactive': !bookmark[row.txt_question_id]}"
              title="Click to set reminder">
              {{i+1}}
              <!-- <span>
                <i class="fa fa-bookmark" aria-hidden="true"></i>
              </span> -->
            </div>
          </td>
          <td>
            <a (click)="click('overviewmodal');goToQuestion(row.txt_question_id)" [innerHTML]="row.txt_question"></a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

<!-- calculator Modal -->
<div id="calculatormodal" class="modal-window">
  <div class="modal_body">
    <div class="modal_header">
      <h1><i class="fad fa-calculator"></i> Calculator</h1>
      <span title="Close" class="modal-close" (click)="click('calculatormodal')"><i class="fad fa-times"></i></span>
    </div>
    <div class="modal_content">

      <iframe src="https://factory.mileseducation.com/Calculator/Calculator.html" class="calculator"
        style="overflow-y: hidden; height:300px;width:99% !important;"></iframe>


      <!-- <div id="calculator">
        <div class="top"> <span class="clear">C</span>
          <div class="screen"></div>
        </div>
        <div class="keys"><span>7</span><span>8</span><span>9</span><span
            class="oper">+</span><span>4</span><span>5</span><span>6</span><span
            class="oper">-</span><span>1</span><span>2</span><span>3</span><span
            class="oper">÷</span><span>0</span><span>.</span><span class="eva">=</span><span class="oper">x</span></div>
      </div> -->
    </div>
  </div>
</div>

<!-- Submit Test Modal -->
<div id="submittestmodal" class="modal-window">
  <div class="modal_body">
    <div class="modal_header">
      <!-- <p><span><i class="fad fa-exclamation"></i></span> You have skipped one or more questions in this testlet.</p> -->
      <span title="Close" class="modal-close" (click)="click('submittestmodal')"><i class="fad fa-times"></i></span>
    </div>
    <div class="modal_content">
      <div class="question_numbers_box mb-0" *ngIf="!wctMode">
        <span>Navigate To: </span>
        <ul>
          <li *ngFor="let row of mcqQuestionList; let i=index"
            (click)="click('submittestmodal');goToQuestion(row.txt_question_id)"
            [ngStyle]="{background:attemptQuestion[row.txt_question_id]?'#3e7345':'#808080'}">
            {{i+1}}</li>
        </ul>
      </div>
      <p class="ml-3 mb-2">Are you sure that you want to submit? If yes, Click on “Submit Test”. </p>
    </div>
    <div class="modal_footer">
      <button type="button" class="btn save_btn" (click)="wctMode?wctSubmitExam():submitExam();">Submit
        {{wctMode?'Testlet':'Test'}}</button>
      <button type="button" class="btn return_btn" (click)="click('submittestmodal')">Return To
        {{wctMode?'Testlet':'Test'}}</button>
    </div>
  </div>
</div>

<!-- Quit Modal -->
<div id="quitmodal" class="modal-window">
  <div class="modal_body">
    <div class="modal_header">
      <span class="modal-title w-100 modal-close text-left pl-3"><i
          class="fad fa-exclamation-triangle mr-2"></i>Attention</span>
      <span title="Close" class="modal-close" (click)="click('quitmodal')"><i class="fad fa-times"></i></span>
    </div>
    <div class="modal_content text-center p-4">
      <span>If you would like to review your answers, click on “Return to Test” or click on "Quit".
      </span>
    </div>
    <div class="modal_footer py-1 px-2">
      <button type="button" class="btn return_btn" style="background-color: #0c376f !important;" (click)="click('quitmodal')">Return to Test</button>
      <button type="button" class="btn save_btn" (click)="quit();click('quitmodal')">Quit</button>
    </div>
  </div>
</div>