import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-jobs-popup',
  templateUrl: './jobs-popup.component.html',
  styleUrls: ['./jobs-popup.component.scss']
})
export class JobsPopupComponent implements OnInit {

  constructor(public modalRef: MDBModalRef, public router: Router) { }

  ngOnInit(): void { window.scroll({top:0,left:0,behavior:"smooth"});
  }
  hideModal(){
    this.modalRef.hide();
  }
  proceedModal(){
    this.modalRef.hide();
    if(localStorage.getItem('otb_id'))
    this.router.navigate(['/lms/jobs/']);
    else
    this.router.navigate(['/lms/jobs/'])
  }
}
