<div class="modal-content">
  <div class="modal-header py-2" style="background: #06345B;color: #fff;">
    <h4 class="modal-title w-100 text-center font-weight-bold" id="myModalLabel">Exam Gameplan</h4>
  </div>
  <div class="modal-body">
    <div class="container-fluid pb-2">
      <div class="game-modal-exam">
        <div class="game-modal-intro">
          <p>Hi&nbsp;{{studentDetails.stud_name}},<br>We want to know your tentative plan for the CPA exams
            so we can set up your
            LMS. </p>
        </div>
        <div class="row">
          <div class="col-12 mx-auto" [ngClass]="isMobile?'px-0':''">
            <p class="mt-4 status-game-modal">Your Current Gameplan Status : </p>
            <div class=" d-flex align-content-around align-items-center game-modal-status">
              <!-- <label class="control-label"><b>Your Current Gameplan Status : </b></label> -->
              <label *ngIf="evalDataStatus" class="control-label my-auto form-control">{{evalDataStatus}}</label>
              <!-- <input type="text" disabled class="form-contol w-100 text-center" [(ngModel)]="evalDataStatus"> -->

              <!-- <ng-select class="w-100 mx-auto" [ngClass]="!isMobile?'custom':'custom-mobile'" [items]="evalOption" name="evalData"
                      bindLabel="option" [(ngModel)]="evalDataId" bindValue="gameplan_evaluation_confirmation_id"
                      placeholder="Choose option" required [readonly]="!editStatus" disabled>
                    </ng-select> -->
              <ng-container *ngIf="evalDataStatus">

                <button *ngIf="!editStatus && evalDataVal" mdbBtn type="button" color="primary" size="sm" class="my-auto game-medit"
                  (click)="editStatus = true;subjectStatus = false" style="background: #06345B !important;">Edit</button>

                <button *ngIf="editStatus && evalDataVal" mdbBtn type="button" color="danger" size="sm" class="my-auto game-mback"
                  (click)="editStatus = false;subjectStatus = true" style="background: #06345B !important;">Back</button>
              </ng-container>
            </div>

            <div *ngIf="editStatus || !!!evalDataStatus">
              <!-- <app-eva-confirmation></app-eva-confirmation> -->
              <div class="row mx-0" *ngIf="evalOption.length > 0">
                <div class="col-12 px-0">
                  <div *ngFor="let eval of evalOption">
                    <ng-container *ngIf="eval.gameplan_evaluation_confirmation_id !=13">
                      <div *ngIf="evalData?.studyplanner_status != null" class="form-check py-1 px-0">
                        <input *ngIf="eval.option == evalData?.studyplanner_status" type="radio" class="form-check-input"
                          id="evalOption_{{eval.gameplan_evaluation_confirmation_id}}" name="evalOption"
                          value="{{eval.gameplan_evaluation_confirmation_id}}" checked (change)="onChange($event)"
                          mdbInput>
                        <input *ngIf="eval.option != evalData?.studyplanner_status" type="radio" class="form-check-input"
                          id="evalOption_{{eval.gameplan_evaluation_confirmation_id}}" name="evalOption"
                          value="{{eval.gameplan_evaluation_confirmation_id}}" (change)="onChange($event)" mdbInput>
                        <label class="form-check-label" for="evalOption_{{eval.gameplan_evaluation_confirmation_id}}"
                          style="font-size:14px">{{eval.option}}</label>
                      </div>
                      <div *ngIf="evalData?.studyplanner_status == null" class="form-check py-1 px-0">
                        <input *ngIf="eval.option == evalData?.gameplan_current_status" type="radio"
                          class="form-check-input" id="evalOption_{{eval.gameplan_evaluation_confirmation_id}}"
                          name="evalOption" value="{{eval.gameplan_evaluation_confirmation_id}}" checked
                          (change)="onChange($event)" mdbInput>
                        <input *ngIf="eval.option != evalData?.gameplan_current_status" type="radio"
                          class="form-check-input" id="evalOption_{{eval.gameplan_evaluation_confirmation_id}}"
                          name="evalOption" value="{{eval.gameplan_evaluation_confirmation_id}}"
                          (change)="onChange($event)" mdbInput>
                        <label class="form-check-label" for="evalOption_{{eval.gameplan_evaluation_confirmation_id}}"
                          style="font-size:14px">{{eval.option}}</label>
                      </div>
                    </ng-container>
                  </div>
                  <!-- Do it on monday *ngIf="eval.option == evalData?.gameplan_current_status && eval.previous_gameplan_status == undefined" -->
                </div>
                <button type="button" mdbBtn color="primary" [disabled]="evalnts_status == 0" style="background: #06345B;"
                  class="relative waves-light btn btn-md" mdbWavesEffect
                  (click)="updateEducationMandate()">Proceed</button>

              </div>
            </div>


          </div>

        </div>

        <div class="bec-choose" *ngIf="!editStatus">
            <p>Have you appeared and passed Business Environment and Concepts (BEC) ?</p>
            <small>Choose Yes only if you have passed BEC or appeared for BEC and awaiting results.</small>
            <div class="bec-choose-option">
              <div *ngIf="chooseActive == 0" class="form-check">
                <input class="form-check-input" type="radio" name="bec" id="YES" value="YES" (change)="toggleInput(1, $event)" mdbInput>
                <label class="form-check-label" for="YES">
                  YES
                </label>
              </div>
              <div *ngIf="chooseActive == 1" class="form-check">
                <input class="form-check-input" type="radio" name="bec" id="YES" value="YES" checked="checked" (change)="toggleInput(1, $event)" mdbInput>
                <label class="form-check-label" for="YES">
                  YES
                </label>
              </div>
              <div *ngIf="chooseActive == 1" class="form-check">
                <input class="form-check-input" type="radio" name="bec" id="NO" value="NO" (change)="toggleInput(2, $event)" mdbInput>
                <label class="form-check-label" for="NO">
                  NO
                </label>
              </div>
              <div *ngIf="chooseActive == 0" class="form-check">
                <input class="form-check-input" type="radio" name="bec" id="NO" value="NO" (change)="toggleInput(2, $event)" mdbInput>
                <label class="form-check-label" for="NO">
                  NO
                </label>
              </div>
              <div *ngIf="chooseActive == 2" class="form-check">
                <input class="form-check-input" type="radio" name="bec" id="YES" value="YES" (change)="toggleInput(1, $event)" mdbInput>
                <label class="form-check-label" for="YES">
                  YES
                </label>
              </div>
              <div *ngIf="chooseActive == 2" class="form-check">
                <input class="form-check-input" type="radio" name="bec" id="NO" value="NO" checked="checked" (change)="toggleInput(2, $event)" mdbInput>
                <label class="form-check-label" for="NO">
                  NO
                </label>
              </div>
            </div>
        </div>
        
        <div *ngIf="chooseActive == 1">
          <ng-container *ngIf="subjectStatus">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-6 mx-auto" [ngClass]="isMobile?'px-0':''">
                <div class="mt-4 d-flex align-content-around align-items-center">
                  <div class="d-flex flex-column mx-auto game-plan-block-size subject-block-aud">
                    <form name="audForm" #audForm="ngForm" class="mx-auto w-100">
                      <div style="margin: 5px;text-align: center; font-family: var(--san_francisco_bold); margin-bottom: 10px; font-size: 16px; color: #212529; font-style: normal; line-height: normal; letter-spacing: 0.36px;">
                        Auditing &amp; Attestation</div>
                      <div style="margin: 5px;" class="md-form">
                        <ng-select class="mb-3 w-100 mx-auto" [ngClass]="!isMobile?'custom':'custom-mobile'"
                          [items]="statusList" name="audstatusList" bindLabel="exam_status_type"
                          [(ngModel)]="audData.exam_status" bindValue="exam_status_id" placeholder="Choose option"
                          (change)="audChange()" required>
                        </ng-select>
                      </div>
  
                      <div class="d-flex flex-fill">
                        <div class="col mx-1 px-0">
                          <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                            *ngIf="audData.exam_status===1 || audData.exam_status===2 || audData.exam_status===5">
                            <input type="text" id="plannercalender" class="bg-white form-control" name="date"
                              [(ngModel)]="audData.date_of_exam" (focus)="monthModal.show();switchId=1" required mdbInput
                              mdbValidate>
                            <label for="plannercalender">Planned Month</label>
                          </div>
                          <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                            *ngIf="audData.exam_status===3 || audData.exam_status===6">
                            <mdb-date-picker [outlineInput]="true" id="form2" name="mydate" [options]="myDatePickerOptions"
                              class="custom-date-picker-input" [ngClass]="audData.exam_status===4 ? 'custom-width':''"
                              mdbValidate mdbInput [(ngModel)]="audData.date_of_exam" [placeholder]="'Scheduled date'"
                              required>
                            </mdb-date-picker>
                          </div>
                          <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                            *ngIf="audData.exam_status===4 || audData.exam_status===7">
                            <mdb-date-picker [outlineInput]="true" id="passform2" name="mypassdate"
                              [options]="passedDateOption" class="custom-date-picker-input"
                              [ngClass]="audData.exam_status===4 ? 'custom-width':''" mdbValidate mdbInput
                              [(ngModel)]="audData.date_of_exam" [placeholder]="'Exam date'" required>
                            </mdb-date-picker>
                          </div>
                        </div>
                        <div class="col mx-1 px-0" *ngIf="audData.exam_status===4">
                          <div class="md-form mt-1 mb-2 gamePlan-Subject-input"
                            [ngClass]="audData.exam_status===4 ? 'custom-width-score':''">
                            <ng-select [items]="scoreArray" name="audscore" [(ngModel)]="audData.score"
                              placeholder="Choose score" required>
                            </ng-select>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
  
              </div>
              <div class="col-12 col-md-12 col-lg-6 mx-auto" [ngClass]="isMobile?'px-0':''">
                <div class="mt-4 d-flex align-content-around align-items-center">
                  <div class="d-flex flex-column mx-auto game-plan-block-size subject-block-bec">
                    <form name="becForm" #becForm="ngForm" class="mx-auto w-100">
                      <div style="margin: 5px;text-align: center; font-family: var(--san_francisco_bold); margin-bottom: 10px; font-size: 16px; color: #212529; font-style: normal; line-height: normal; letter-spacing: 0.36px;">
                        Business Environment &amp; Concepts</div>
                      <div style="margin: 5px;" class="md-form">
                        <ng-select class="mb-3 w-100 mx-auto" [ngClass]="!isMobile?'custom':'custom-mobile'"
                          [items]="becstatusList" name="becstatusList" bindLabel="exam_status_type"
                          [(ngModel)]="becData.exam_status" bindValue="exam_status_id" placeholder="Choose option" required
                          (change)="becChange()">
                        </ng-select>
                      </div>
  
                      <div class="d-flex flex-fill">
                        <div class="col mx-1 px-0">
                          <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                            *ngIf="becData.exam_status===1 || becData.exam_status===2 || becData.exam_status===5">
                            <input type="text" id="becplannercalender" class="bg-white form-control" name="becdate"
                              [(ngModel)]="becData.date_of_exam" (focus)="monthModal.show();switchId=2" required mdbInput
                              mdbValidate>
                            <label for="becplannercalender">Planned Month</label>
                          </div>
                          <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                            *ngIf="becData.exam_status===3 || becData.exam_status===6">
                            <mdb-date-picker [outlineInput]="true" id="becform2" name="becmydate"
                              class="custom-date-picker-input" [ngClass]="becData.exam_status===4 ? 'custom-width':''"
                              [options]="myDatePickerOptions" mdbValidate mdbInput [(ngModel)]="becData.date_of_exam"
                              [placeholder]="'Scheduled date'" required>
                            </mdb-date-picker>
                          </div>
                          <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                            *ngIf=" becData.exam_status===4  || becData.exam_status===7">
                            <mdb-date-picker [outlineInput]="true" id="bec2form2" name="bec2mydate"
                              class="custom-date-picker-input" [ngClass]="becData.exam_status===4 ? 'custom-width':''"
                              [options]="passedDateOption" mdbValidate mdbInput [(ngModel)]="becData.date_of_exam"
                              [placeholder]="'Exam date'" required>
                            </mdb-date-picker>
                          </div>
                        </div>
                        <div class="col mx-1 px-0" *ngIf="becData.exam_status===4">
                          <div class="md-form mt-1 mb-2 gamePlan-Subject-input"
                            [ngClass]="becData.exam_status===4 ? 'custom-width-score':''">
                            <ng-select [items]="scoreArray" name="becscore" [(ngModel)]="becData.score"
                              placeholder="Choose score" required>
                            </ng-select>
                          </div>
                        </div>
                      </div>
                    </form>
  
                  </div>
                </div>
  
              </div>
  
            </div>
  
            <div class="row">
              <div class="col-12 col-md-12 col-lg-6 mx-auto" [ngClass]="isMobile?'px-0':''">
                <div class="mt-4 d-flex align-content-around align-items-center">
                  <div class="d-flex flex-column mx-auto game-plan-block-size subject-block-far">
  
                    <form name="farForm" #farForm="ngForm" class="mx-auto w-100">
                      <div style="margin: 5px;text-align: center; font-family: var(--san_francisco_bold); margin-bottom: 10px; font-size: 16px; color: #212529; font-style: normal; line-height: normal; letter-spacing: 0.36px;">
                        Financial Accounting &amp; Reporting</div>
                      <div style="margin: 5px;" class="md-form">
                        <ng-select class="mb-3 w-100 mx-auto" [ngClass]="!isMobile?'custom':'custom-mobile'"
                          [items]="statusList" name="farstatusList" bindLabel="exam_status_type"
                          [(ngModel)]="farData.exam_status" bindValue="exam_status_id" placeholder="Choose option" required
                          (change)="farChange()">
                        </ng-select>
                      </div>
  
                      <div class="d-flex flex-fill">
                        <div class="col mx-1 px-0">
                          <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                            *ngIf="farData.exam_status===1 || farData.exam_status===2 || farData.exam_status===5">
                            <input type="text" id="farplannercalender" class="bg-white form-control" name="fardate"
                              [(ngModel)]="farData.date_of_exam" (focus)="monthModal.show();switchId=3" required mdbInput
                              mdbValidate>
                            <label for="farplannercalender">Planned Month</label>
                          </div>
                          <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                            *ngIf="farData.exam_status===3 || farData.exam_status===6">
                            <mdb-date-picker [outlineInput]="true" id="farform2" name="farmydate"
                              class="custom-date-picker-input" [ngClass]="farData.exam_status===4 ? 'custom-width':''"
                              [options]="myDatePickerOptions" mdbValidate mdbInput [(ngModel)]="farData.date_of_exam"
                              [placeholder]="'Scheduled date'" required>
                            </mdb-date-picker>
                          </div>
                          <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                            *ngIf="farData.exam_status===4 || farData.exam_status===7">
                            <mdb-date-picker [outlineInput]="true" id="far2form2" name="far2mydate"
                              class="custom-date-picker-input" [ngClass]="farData.exam_status===4 ? 'custom-width':''"
                              [options]="passedDateOption" mdbValidate mdbInput [(ngModel)]="farData.date_of_exam"
                              [placeholder]="'Exam date'" required>
                            </mdb-date-picker>
                          </div>
                        </div>
                        <div class="col mx-1 px-0" *ngIf="farData.exam_status===4">
                          <div class="md-form mt-1 mb-2 gamePlan-Subject-input"
                            [ngClass]="farData.exam_status===4 ? 'custom-width-score':''">
                            <ng-select [items]="scoreArray" name="farscore" [(ngModel)]="farData.score"
                              placeholder="Choose score" required>
                            </ng-select>
                          </div>
                        </div>
                      </div>
                    </form>
  
                  </div>
                </div>
  
              </div>
              <div class="col-12 col-md-12 col-lg-6 mx-auto" [ngClass]="isMobile?'px-0':''">
                <div class="mt-4 d-flex align-content-around align-items-center">
                  <div class="d-flex flex-column mx-auto game-plan-block-size subject-block-reg">
  
  
                    <form name="regForm" #regForm="ngForm" class="mx-auto w-100">
                      <div style="margin: 5px;text-align: center; font-family: var(--san_francisco_bold); margin-bottom: 10px; font-size: 16px; color: #212529; font-style: normal; line-height: normal; letter-spacing: 0.36px;">
                        Regulation</div>
                      <div style="margin: 5px;" class="md-form">
                        <ng-select class="mb-3 w-100 mx-auto" [ngClass]="!isMobile?'custom':'custom-mobile'"
                          [items]="statusList" name="regstatusList" bindLabel="exam_status_type"
                          [(ngModel)]="regData.exam_status" bindValue="exam_status_id" placeholder="Choose option" required
                          (change)="regChange()">
                        </ng-select>
                      </div>
  
                      <div class="d-flex flex-fill">
                        <div class="col mx-1 px-0">
                          <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                            *ngIf="regData.exam_status===1 || regData.exam_status===2 || regData.exam_status===5">
                            <input type="text" id="regplannercalender" class="bg-white form-control" name="regdate"
                              [(ngModel)]="regData.date_of_exam" (focus)="monthModal.show();switchId=4" required mdbInput
                              mdbValidate>
                            <label for="regplannercalender">Planned Month</label>
                          </div>
                          <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                            *ngIf="regData.exam_status===3 || regData.exam_status===6">
                            <mdb-date-picker [outlineInput]="true" id="regform2" name="regmydate"
                              class="custom-date-picker-input" [ngClass]="regData.exam_status===4 ? 'custom-width':''"
                              [options]="myDatePickerOptions" mdbValidate mdbInput [(ngModel)]="regData.date_of_exam"
                              [placeholder]="'Scheduled date'" required>
                            </mdb-date-picker>
                          </div>
                          <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                            *ngIf="regData.exam_status===4 || regData.exam_status===7">
                            <mdb-date-picker [outlineInput]="true" id="reg2form2" name="reg2mydate"
                              class="custom-date-picker-input" [ngClass]="regData.exam_status===4 ? 'custom-width':''"
                              [options]="passedDateOption" mdbValidate mdbInput [(ngModel)]="regData.date_of_exam"
                              [placeholder]="'Exam date'" required>
                            </mdb-date-picker>
                          </div>
                        </div>
                        <div class="col mx-1 px-0" *ngIf="regData.exam_status===4">
                          <div class="md-form mt-1 mb-2 gamePlan-Subject-input"
                            [ngClass]="regData.exam_status===4 ? 'custom-width-score':''">
                            <ng-select [items]="scoreArray" name="regscore" [(ngModel)]="regData.score"
                              placeholder="Choose score" required>
                            </ng-select>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
  
          </ng-container>
        </div>
  
        
        <div *ngIf="chooseActive == 2" class="game-modal-accordions">

          <div class="row">
            <div class="col-12 col-md-12 col-lg-6 mx-auto" [ngClass]="isMobile?'px-0':''">
              <div class="mt-4 d-flex align-content-around align-items-center">
                <div class="d-flex flex-column mx-auto game-plan-block-size subject-block-aud">
                  <form name="audForm" #audForm="ngForm" class="mx-auto w-100">
                    <div class="game-sub-title" style="margin: 5px;text-align: center; font-family: var(--san_francisco_bold); margin-bottom: 10px; font-size: 16px; color: #212529; font-style: normal; line-height: normal; letter-spacing: 0.36px;">
                      Auditing &amp; Attestation</div>
                    <div style="margin: 5px;" class="md-form">
                      <ng-select class="mb-3 w-100 mx-auto" [ngClass]="!isMobile?'custom':'custom-mobile'"
                        [items]="statusList" name="audstatusList" bindLabel="exam_status_type"
                        [(ngModel)]="audData.exam_status" bindValue="exam_status_id" placeholder="Choose option"
                        (change)="audChange()" required>
                      </ng-select>
                    </div>
  
                    <div class="game-modal-fileds">
                      <div class="col mx-1 px-0">
                        <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                          *ngIf="audData.exam_status===1 || audData.exam_status===2 || audData.exam_status===5">
                          <input type="text" id="plannercalender" class="bg-white form-control" name="date"
                            [(ngModel)]="audData.date_of_exam" (focus)="monthModal.show();switchId=1" required mdbInput
                            mdbValidate>
                          <label for="plannercalender">Planned Month</label>
                        </div>
                        <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                          *ngIf="audData.exam_status===3 || audData.exam_status===6">
                          <mdb-date-picker [outlineInput]="true" id="form2" name="mydate" [options]="myDatePickerOptions"
                            class="custom-date-picker-input" [ngClass]="audData.exam_status===4 ? 'custom-width':''"
                            mdbValidate mdbInput [(ngModel)]="audData.date_of_exam" [placeholder]="'Scheduled date'"
                            required>
                          </mdb-date-picker>
                        </div>
                        <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                          *ngIf="audData.exam_status===4 || audData.exam_status===7">
                          <mdb-date-picker [outlineInput]="true" id="passform2" name="mypassdate"
                            [options]="passedDateOption" class="custom-date-picker-input"
                            [ngClass]="audData.exam_status===4 ? 'custom-width':''" mdbValidate mdbInput
                            [(ngModel)]="audData.date_of_exam" [placeholder]="'Exam date'" required>
                          </mdb-date-picker>
                        </div>
                      </div>
                      <div class="col mx-1 px-0" *ngIf="audData.exam_status===4">
                        <div class="md-form mt-1 mb-2 gamePlan-Subject-input"
                          [ngClass]="audData.exam_status===4 ? 'custom-width-score':''">
                          <ng-select [items]="scoreArray" name="audscore" [(ngModel)]="audData.score"
                            placeholder="Choose score" required>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
  
            </div>
            <div class="col-12 col-md-12 col-lg-6 mx-auto" [ngClass]="isMobile?'px-0':''">
              <div class="mt-4 d-flex align-content-around align-items-center">
                <div class="d-flex flex-column mx-auto game-plan-block-size subject-block-reg">
  
  
                  <form name="regForm" #regForm="ngForm" class="mx-auto w-100">
                    <div class="game-sub-title" style="margin: 5px;text-align: center; font-family: var(--san_francisco_bold); margin-bottom: 10px; font-size: 16px; color: #212529; font-style: normal; line-height: normal; letter-spacing: 0.36px;">
                      Regulation</div>
                    <div style="margin: 5px;" class="md-form">
                      <ng-select class="mb-3 w-100 mx-auto" [ngClass]="!isMobile?'custom':'custom-mobile'"
                        [items]="statusList" name="regstatusList" bindLabel="exam_status_type"
                        [(ngModel)]="regData.exam_status" bindValue="exam_status_id" placeholder="Choose option" required
                        (change)="regChange()">
                      </ng-select>
                    </div>
  
                    <div class="game-modal-fileds">
                      <div class="col mx-1 px-0">
                        <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                          *ngIf="regData.exam_status===1 || regData.exam_status===2 || regData.exam_status===5">
                          <input type="text" id="regplannercalender" class="bg-white form-control" name="regdate"
                            [(ngModel)]="regData.date_of_exam" (focus)="monthModal.show();switchId=4" required mdbInput
                            mdbValidate>
                          <label for="regplannercalender">Planned Month</label>
                        </div>
                        <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                          *ngIf="regData.exam_status===3 || regData.exam_status===6">
                          <mdb-date-picker [outlineInput]="true" id="regform2" name="regmydate"
                            class="custom-date-picker-input" [ngClass]="regData.exam_status===4 ? 'custom-width':''"
                            [options]="myDatePickerOptions" mdbValidate mdbInput [(ngModel)]="regData.date_of_exam"
                            [placeholder]="'Scheduled date'" required>
                          </mdb-date-picker>
                        </div>
                        <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                          *ngIf="regData.exam_status===4 || regData.exam_status===7">
                          <mdb-date-picker [outlineInput]="true" id="reg2form2" name="reg2mydate"
                            class="custom-date-picker-input" [ngClass]="regData.exam_status===4 ? 'custom-width':''"
                            [options]="passedDateOption" mdbValidate mdbInput [(ngModel)]="regData.date_of_exam"
                            [placeholder]="'Exam date'" required>
                          </mdb-date-picker>
                        </div>
                      </div>
                      <div class="col mx-1 px-0" *ngIf="regData.exam_status===4">
                        <div class="md-form mt-1 mb-2 gamePlan-Subject-input"
                          [ngClass]="regData.exam_status===4 ? 'custom-width-score':''">
                          <ng-select [items]="scoreArray" name="regscore" [(ngModel)]="regData.score"
                            placeholder="Choose score" required>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-6 mx-auto" [ngClass]="isMobile?'px-0':''">
              <div class="mt-4 d-flex align-content-around align-items-center">
                <div class="d-flex flex-column mx-auto game-plan-block-size subject-block-far">
  
                  <form name="farForm" #farForm="ngForm" class="mx-auto w-100">
                    <div class="game-sub-title" style="margin: 5px;text-align: center; font-family: var(--san_francisco_bold); margin-bottom: 10px; font-size: 16px; color: #212529; font-style: normal; line-height: normal; letter-spacing: 0.36px;">
                      Financial Accounting &amp; Reporting</div>
                    <div style="margin: 5px;" class="md-form">
                      <ng-select class="mb-3 w-100 mx-auto" [ngClass]="!isMobile?'custom':'custom-mobile'"
                        [items]="statusList" name="farstatusList" bindLabel="exam_status_type"
                        [(ngModel)]="farData.exam_status" bindValue="exam_status_id" placeholder="Choose option" required
                        (change)="farChange()">
                      </ng-select>
                    </div>
  
                    <div class="game-modal-fileds">
                      <div class="col mx-1 px-0">
                        <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                          *ngIf="farData.exam_status===1 || farData.exam_status===2 || farData.exam_status===5">
                          <input type="text" id="farplannercalender" class="bg-white form-control" name="fardate"
                            [(ngModel)]="farData.date_of_exam" (focus)="monthModal.show();switchId=3" required mdbInput
                            mdbValidate>
                          <label for="farplannercalender">Planned Month</label>
                        </div>
                        <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                          *ngIf="farData.exam_status===3 || farData.exam_status===6">
                          <mdb-date-picker [outlineInput]="true" id="farform2" name="farmydate"
                            class="custom-date-picker-input" [ngClass]="farData.exam_status===4 ? 'custom-width':''"
                            [options]="myDatePickerOptions" mdbValidate mdbInput [(ngModel)]="farData.date_of_exam"
                            [placeholder]="'Scheduled date'" required>
                          </mdb-date-picker>
                        </div>
                        <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                          *ngIf="farData.exam_status===4 || farData.exam_status===7">
                          <mdb-date-picker [outlineInput]="true" id="far2form2" name="far2mydate"
                            class="custom-date-picker-input" [ngClass]="farData.exam_status===4 ? 'custom-width':''"
                            [options]="passedDateOption" mdbValidate mdbInput [(ngModel)]="farData.date_of_exam"
                            [placeholder]="'Exam date'" required>
                          </mdb-date-picker>
                        </div>
                      </div>
                      <div class="col mx-1 px-0" *ngIf="farData.exam_status===4">
                        <div class="md-form mt-1 mb-2 gamePlan-Subject-input"
                          [ngClass]="farData.exam_status===4 ? 'custom-width-score':''">
                          <ng-select [items]="scoreArray" name="farscore" [(ngModel)]="farData.score"
                            placeholder="Choose score" required>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </form>
  
                </div>
              </div>
  
            </div>
            <div class="col-12 col-md-12 col-lg-6 mx-auto" [ngClass]="isMobile?'px-0':''">
              <div class="mt-4 d-flex align-content-around align-items-center">
                <div class="d-flex flex-column mx-auto game-plan-block-size subject-block-bar">
                  <form name="barForm" #barForm="ngForm" class="mx-auto w-100">
                    <div class="game-sub-title" style="margin: 5px;text-align: center; font-family: var(--san_francisco_bold); margin-bottom: 10px; font-size: 16px; color: #212529; font-style: normal; line-height: normal; letter-spacing: 0.36px;">
                      Business Analysis & Reporting</div>
                    <div style="margin: 5px;" class="md-form">
                      <ng-select class="mb-3 w-100 mx-auto" [ngClass]="!isMobile?'custom':'custom-mobile'"
                        [items]="na_statusList" name="becstatusList" bindLabel="exam_status_type"
                        [(ngModel)]="barData.exam_status" bindValue="exam_status_id" placeholder="Choose option" required
                        (change)="barChange()">
                      </ng-select>
                    </div>
  
                    <div class="game-modal-fileds">
                      <div class="col mx-1 px-0">
                        <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                          *ngIf="barData.exam_status===1 || barData.exam_status===2 || barData.exam_status===5">
                          <input type="text" id="barplannercalender" class="bg-white form-control" name="bardate"
                            [(ngModel)]="barData.date_of_exam" (focus)="monthModal.show();switchId=5" required mdbInput
                            mdbValidate>
                          <label for="barplannercalender">Planned Month</label>
                        </div>
                        <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                          *ngIf="barData.exam_status===3 || barData.exam_status===6">
                          <mdb-date-picker [outlineInput]="true" id="barform2" name="barmydate"
                            class="custom-date-picker-input" [ngClass]="barData.exam_status===4 ? 'custom-width':''"
                            [options]="myDatePickerOptions" mdbValidate mdbInput [(ngModel)]="barData.date_of_exam"
                            [placeholder]="'Scheduled date'" required>
                          </mdb-date-picker>
                        </div>
                        <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                          *ngIf=" barData.exam_status===4  || barData.exam_status===7">
                          <mdb-date-picker [outlineInput]="true" id="bar2form2" name="bar2mydate"
                            class="custom-date-picker-input" [ngClass]="barData.exam_status===4 ? 'custom-width':''"
                            [options]="passedDateOption" mdbValidate mdbInput [(ngModel)]="barData.date_of_exam"
                            [placeholder]="'Exam date'" required>
                          </mdb-date-picker>
                        </div>
                      </div>
                      <div class="col mx-1 px-0" *ngIf="barData.exam_status===4">
                        <div class="md-form mt-1 mb-2 gamePlan-Subject-input"
                          [ngClass]="barData.exam_status===4 ? 'custom-width-score':''">
                          <ng-select [items]="scoreArray" name="barscore" [(ngModel)]="barData.score"
                            placeholder="Choose score" required>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </form>
  
                </div>
              </div>
  
            </div>
          </div>

          <!-- <mdb-accordion [multiple]="true">
            <mdb-accordion-item [collapsed]="false">
                <mdb-accordion-item-head>
                  Core Exam Sections
                </mdb-accordion-item-head>
                <mdb-accordion-item-body>
                    <div class="game-yessubject">
                      <div class="game-sub-itm" [ngClass]="isMobile?'px-0':''">
                        <div class="mt-4 d-flex align-content-around align-items-center">
                          <div class="d-flex flex-column mx-auto game-plan-block-size subject-block-aud">
                            <form name="audForm" #audForm="ngForm" class="mx-auto w-100">
                              <div class="game-sub-title" style="margin: 5px;text-align: center; font-family: var(--san_francisco_bold); margin-bottom: 10px; font-size: 16px; color: #212529; font-style: normal; line-height: normal; letter-spacing: 0.36px;">
                                Auditing &amp; Attestation</div>
                              <div style="margin: 5px;" class="md-form">
                                <ng-select class="mb-3 w-100 mx-auto" [ngClass]="!isMobile?'custom':'custom-mobile'"
                                  [items]="statusList" name="audstatusList" bindLabel="exam_status_type"
                                  [(ngModel)]="audData.exam_status" bindValue="exam_status_id" placeholder="Choose option"
                                  (change)="audChange()" required>
                                </ng-select>
                              </div>
            
                              <div class="game-modal-fileds">
                                <div class="col mx-1 px-0">
                                  <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                                    *ngIf="audData.exam_status===1 || audData.exam_status===2 || audData.exam_status===5">
                                    <input type="text" id="plannercalender" class="bg-white form-control" name="date"
                                      [(ngModel)]="audData.date_of_exam" (focus)="monthModal.show();switchId=1" required mdbInput
                                      mdbValidate>
                                    <label for="plannercalender">Planned Month</label>
                                  </div>
                                  <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                                    *ngIf="audData.exam_status===3 || audData.exam_status===6">
                                    <mdb-date-picker [outlineInput]="true" id="form2" name="mydate" [options]="myDatePickerOptions"
                                      class="custom-date-picker-input" [ngClass]="audData.exam_status===4 ? 'custom-width':''"
                                      mdbValidate mdbInput [(ngModel)]="audData.date_of_exam" [placeholder]="'Scheduled date'"
                                      required>
                                    </mdb-date-picker>
                                  </div>
                                  <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                                    *ngIf="audData.exam_status===4 || audData.exam_status===7">
                                    <mdb-date-picker [outlineInput]="true" id="passform2" name="mypassdate"
                                      [options]="passedDateOption" class="custom-date-picker-input"
                                      [ngClass]="audData.exam_status===4 ? 'custom-width':''" mdbValidate mdbInput
                                      [(ngModel)]="audData.date_of_exam" [placeholder]="'Exam date'" required>
                                    </mdb-date-picker>
                                  </div>
                                </div>
                                <div class="col mx-1 px-0" *ngIf="audData.exam_status===4">
                                  <div class="md-form mt-1 mb-2 gamePlan-Subject-input"
                                    [ngClass]="audData.exam_status===4 ? 'custom-width-score':''">
                                    <ng-select [items]="scoreArray" name="audscore" [(ngModel)]="audData.score"
                                      placeholder="Choose score" required>
                                    </ng-select>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
            
                      </div>
                      <div class="game-sub-itm" [ngClass]="isMobile?'px-0':''">
                        <div class="mt-4 d-flex align-content-around align-items-center">
                          <div class="d-flex flex-column mx-auto game-plan-block-size subject-block-reg">
            
            
                            <form name="regForm" #regForm="ngForm" class="mx-auto w-100">
                              <div class="game-sub-title" style="margin: 5px;text-align: center; font-family: var(--san_francisco_bold); margin-bottom: 10px; font-size: 16px; color: #212529; font-style: normal; line-height: normal; letter-spacing: 0.36px;">
                                Regulation</div>
                              <div style="margin: 5px;" class="md-form">
                                <ng-select class="mb-3 w-100 mx-auto" [ngClass]="!isMobile?'custom':'custom-mobile'"
                                  [items]="statusList" name="regstatusList" bindLabel="exam_status_type"
                                  [(ngModel)]="regData.exam_status" bindValue="exam_status_id" placeholder="Choose option" required
                                  (change)="regChange()">
                                </ng-select>
                              </div>
            
                              <div class="game-modal-fileds">
                                <div class="col mx-1 px-0">
                                  <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                                    *ngIf="regData.exam_status===1 || regData.exam_status===2 || regData.exam_status===5">
                                    <input type="text" id="regplannercalender" class="bg-white form-control" name="regdate"
                                      [(ngModel)]="regData.date_of_exam" (focus)="monthModal.show();switchId=4" required mdbInput
                                      mdbValidate>
                                    <label for="regplannercalender">Planned Month</label>
                                  </div>
                                  <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                                    *ngIf="regData.exam_status===3 || regData.exam_status===6">
                                    <mdb-date-picker [outlineInput]="true" id="regform2" name="regmydate"
                                      class="custom-date-picker-input" [ngClass]="regData.exam_status===4 ? 'custom-width':''"
                                      [options]="myDatePickerOptions" mdbValidate mdbInput [(ngModel)]="regData.date_of_exam"
                                      [placeholder]="'Scheduled date'" required>
                                    </mdb-date-picker>
                                  </div>
                                  <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                                    *ngIf="regData.exam_status===4 || regData.exam_status===7">
                                    <mdb-date-picker [outlineInput]="true" id="reg2form2" name="reg2mydate"
                                      class="custom-date-picker-input" [ngClass]="regData.exam_status===4 ? 'custom-width':''"
                                      [options]="passedDateOption" mdbValidate mdbInput [(ngModel)]="regData.date_of_exam"
                                      [placeholder]="'Exam date'" required>
                                    </mdb-date-picker>
                                  </div>
                                </div>
                                <div class="col mx-1 px-0" *ngIf="regData.exam_status===4">
                                  <div class="md-form mt-1 mb-2 gamePlan-Subject-input"
                                    [ngClass]="regData.exam_status===4 ? 'custom-width-score':''">
                                    <ng-select [items]="scoreArray" name="regscore" [(ngModel)]="regData.score"
                                      placeholder="Choose score" required>
                                    </ng-select>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="game-sub-itm" [ngClass]="isMobile?'px-0':''">
                        <div class="mt-4 d-flex align-content-around align-items-center">
                          <div class="d-flex flex-column mx-auto game-plan-block-size subject-block-far">
            
                            <form name="farForm" #farForm="ngForm" class="mx-auto w-100">
                              <div class="game-sub-title" style="margin: 5px;text-align: center; font-family: var(--san_francisco_bold); margin-bottom: 10px; font-size: 16px; color: #212529; font-style: normal; line-height: normal; letter-spacing: 0.36px;">
                                Financial Accounting &amp; Reporting</div>
                              <div style="margin: 5px;" class="md-form">
                                <ng-select class="mb-3 w-100 mx-auto" [ngClass]="!isMobile?'custom':'custom-mobile'"
                                  [items]="statusList" name="farstatusList" bindLabel="exam_status_type"
                                  [(ngModel)]="farData.exam_status" bindValue="exam_status_id" placeholder="Choose option" required
                                  (change)="farChange()">
                                </ng-select>
                              </div>
            
                              <div class="game-modal-fileds">
                                <div class="col mx-1 px-0">
                                  <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                                    *ngIf="farData.exam_status===1 || farData.exam_status===2 || farData.exam_status===5">
                                    <input type="text" id="farplannercalender" class="bg-white form-control" name="fardate"
                                      [(ngModel)]="farData.date_of_exam" (focus)="monthModal.show();switchId=3" required mdbInput
                                      mdbValidate>
                                    <label for="farplannercalender">Planned Month</label>
                                  </div>
                                  <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                                    *ngIf="farData.exam_status===3 || farData.exam_status===6">
                                    <mdb-date-picker [outlineInput]="true" id="farform2" name="farmydate"
                                      class="custom-date-picker-input" [ngClass]="farData.exam_status===4 ? 'custom-width':''"
                                      [options]="myDatePickerOptions" mdbValidate mdbInput [(ngModel)]="farData.date_of_exam"
                                      [placeholder]="'Scheduled date'" required>
                                    </mdb-date-picker>
                                  </div>
                                  <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                                    *ngIf="farData.exam_status===4 || farData.exam_status===7">
                                    <mdb-date-picker [outlineInput]="true" id="far2form2" name="far2mydate"
                                      class="custom-date-picker-input" [ngClass]="farData.exam_status===4 ? 'custom-width':''"
                                      [options]="passedDateOption" mdbValidate mdbInput [(ngModel)]="farData.date_of_exam"
                                      [placeholder]="'Exam date'" required>
                                    </mdb-date-picker>
                                  </div>
                                </div>
                                <div class="col mx-1 px-0" *ngIf="farData.exam_status===4">
                                  <div class="md-form mt-1 mb-2 gamePlan-Subject-input"
                                    [ngClass]="farData.exam_status===4 ? 'custom-width-score':''">
                                    <ng-select [items]="scoreArray" name="farscore" [(ngModel)]="farData.score"
                                      placeholder="Choose score" required>
                                    </ng-select>
                                  </div>
                                </div>
                              </div>
                            </form>
            
                          </div>
                        </div>
            
                      </div>
                      <div class="game-sub-itm" [ngClass]="isMobile?'px-0':''">
                        <div class="mt-4 d-flex align-content-around align-items-center">
                          <div class="d-flex flex-column mx-auto game-plan-block-size subject-block-bar">
                            <form name="barForm" #barForm="ngForm" class="mx-auto w-100">
                              <div class="game-sub-title" style="margin: 5px;text-align: center; font-family: var(--san_francisco_bold); margin-bottom: 10px; font-size: 16px; color: #212529; font-style: normal; line-height: normal; letter-spacing: 0.36px;">
                                Business Analysis & Reporting</div>
                              <div style="margin: 5px;" class="md-form">
                                <ng-select class="mb-3 w-100 mx-auto" [ngClass]="!isMobile?'custom':'custom-mobile'"
                                  [items]="na_statusList" name="becstatusList" bindLabel="exam_status_type"
                                  [(ngModel)]="barData.exam_status" bindValue="exam_status_id" placeholder="Choose option" required
                                  (change)="barChange()">
                                </ng-select>
                              </div>
            
                              <div class="game-modal-fileds">
                                <div class="col mx-1 px-0">
                                  <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                                    *ngIf="barData.exam_status===1 || barData.exam_status===2 || barData.exam_status===5">
                                    <input type="text" id="barplannercalender" class="bg-white form-control" name="bardate"
                                      [(ngModel)]="barData.date_of_exam" (focus)="monthModal.show();switchId=5" required mdbInput
                                      mdbValidate>
                                    <label for="barplannercalender">Planned Month</label>
                                  </div>
                                  <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                                    *ngIf="barData.exam_status===3 || barData.exam_status===6">
                                    <mdb-date-picker [outlineInput]="true" id="barform2" name="barmydate"
                                      class="custom-date-picker-input" [ngClass]="barData.exam_status===4 ? 'custom-width':''"
                                      [options]="myDatePickerOptions" mdbValidate mdbInput [(ngModel)]="barData.date_of_exam"
                                      [placeholder]="'Scheduled date'" required>
                                    </mdb-date-picker>
                                  </div>
                                  <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                                    *ngIf=" barData.exam_status===4  || barData.exam_status===7">
                                    <mdb-date-picker [outlineInput]="true" id="bar2form2" name="bar2mydate"
                                      class="custom-date-picker-input" [ngClass]="barData.exam_status===4 ? 'custom-width':''"
                                      [options]="passedDateOption" mdbValidate mdbInput [(ngModel)]="barData.date_of_exam"
                                      [placeholder]="'Exam date'" required>
                                    </mdb-date-picker>
                                  </div>
                                </div>
                                <div class="col mx-1 px-0" *ngIf="barData.exam_status===4">
                                  <div class="md-form mt-1 mb-2 gamePlan-Subject-input"
                                    [ngClass]="barData.exam_status===4 ? 'custom-width-score':''">
                                    <ng-select [items]="scoreArray" name="barscore" [(ngModel)]="barData.score"
                                      placeholder="Choose score" required>
                                    </ng-select>
                                  </div>
                                </div>
                              </div>
                            </form>
            
                          </div>
                        </div>
            
                      </div>
                    </div>
                </mdb-accordion-item-body>
            </mdb-accordion-item>
            <mdb-accordion-item [collapsed]="false">
              <mdb-accordion-item-head>
                Discipline Exam Sections<br>
                <small>Candidates have to update exam status for the chosen Discipline, and update exam status as N/A for the other Disciplines</small>
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <div class="game-yessubject">
                  <div class="game-sub-itm" [ngClass]="isMobile?'px-0':''">
                    <div class="mt-4 d-flex align-content-around align-items-center">
                      <div class="d-flex flex-column mx-auto game-plan-block-size subject-block-bar">
                        <form name="barForm" #barForm="ngForm" class="mx-auto w-100">
                          <div class="game-sub-title" style="margin: 5px;text-align: center; font-family: var(--san_francisco_bold); margin-bottom: 10px; font-size: 16px; color: #212529; font-style: normal; line-height: normal; letter-spacing: 0.36px;">
                            Business Analysis & Reporting</div>
                          <div style="margin: 5px;" class="md-form">
                            <ng-select class="mb-3 w-100 mx-auto" [ngClass]="!isMobile?'custom':'custom-mobile'"
                              [items]="na_statusList" name="becstatusList" bindLabel="exam_status_type"
                              [(ngModel)]="barData.exam_status" bindValue="exam_status_id" placeholder="Choose option" required
                              (change)="barChange()">
                            </ng-select>
                          </div>
        
                          <div class="game-modal-fileds">
                            <div class="col mx-1 px-0">
                              <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                                *ngIf="barData.exam_status===1 || barData.exam_status===2 || barData.exam_status===5">
                                <input type="text" id="barplannercalender" class="bg-white form-control" name="bardate"
                                  [(ngModel)]="barData.date_of_exam" (focus)="monthModal.show();switchId=5" required mdbInput
                                  mdbValidate>
                                <label for="barplannercalender">Planned Month</label>
                              </div>
                              <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                                *ngIf="barData.exam_status===3 || barData.exam_status===6">
                                <mdb-date-picker [outlineInput]="true" id="barform2" name="barmydate"
                                  class="custom-date-picker-input" [ngClass]="barData.exam_status===4 ? 'custom-width':''"
                                  [options]="myDatePickerOptions" mdbValidate mdbInput [(ngModel)]="barData.date_of_exam"
                                  [placeholder]="'Scheduled date'" required>
                                </mdb-date-picker>
                              </div>
                              <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                                *ngIf=" barData.exam_status===4  || barData.exam_status===7">
                                <mdb-date-picker [outlineInput]="true" id="bar2form2" name="bar2mydate"
                                  class="custom-date-picker-input" [ngClass]="barData.exam_status===4 ? 'custom-width':''"
                                  [options]="passedDateOption" mdbValidate mdbInput [(ngModel)]="barData.date_of_exam"
                                  [placeholder]="'Exam date'" required>
                                </mdb-date-picker>
                              </div>
                            </div>
                            <div class="col mx-1 px-0" *ngIf="barData.exam_status===4">
                              <div class="md-form mt-1 mb-2 gamePlan-Subject-input"
                                [ngClass]="barData.exam_status===4 ? 'custom-width-score':''">
                                <ng-select [items]="scoreArray" name="barscore" [(ngModel)]="barData.score"
                                  placeholder="Choose score" required>
                                </ng-select>
                              </div>
                            </div>
                          </div>
                        </form>
        
                      </div>
                    </div>
        
                  </div>
                  <div class="game-sub-itm" [ngClass]="isMobile?'px-0':''">
                    <div class="mt-4 d-flex align-content-around align-items-center">
                      <div class="d-flex flex-column mx-auto game-plan-block-size subject-block-tcp">
        
        
                        <form name="tcpForm" #tcpForm="ngForm" class="mx-auto w-100">
                          <div class="game-sub-title" style="margin: 5px;text-align: center; font-family: var(--san_francisco_bold); margin-bottom: 10px; font-size: 16px; color: #212529; font-style: normal; line-height: normal; letter-spacing: 0.36px;">
                            Tax Compliance & Planning</div>
                          <div style="margin: 5px;" class="md-form">
                            <ng-select class="mb-3 w-100 mx-auto" [ngClass]="!isMobile?'custom':'custom-mobile'"
                              [items]="na_statusList" name="tcpstatusList" bindLabel="exam_status_type"
                              [(ngModel)]="tcpData.exam_status" bindValue="exam_status_id" placeholder="Choose option" required
                              (change)="tcpChange()">
                            </ng-select>
                          </div>
        
                          <div class="game-modal-fileds">
                            <div class="col mx-1 px-0">
                              <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                                *ngIf="tcpData.exam_status===1 || tcpData.exam_status===2 || tcpData.exam_status===5">
                                <input type="text" id="tcpplannercalender" class="bg-white form-control" name="tcpdate"
                                  [(ngModel)]="tcpData.date_of_exam" (focus)="monthModal.show();switchId=6" required mdbInput
                                  mdbValidate>
                                <label for="tcpplannercalender">Planned Month</label>
                              </div>
                              <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                                *ngIf="tcpData.exam_status===3 || tcpData.exam_status===6">
                                <mdb-date-picker [outlineInput]="true" id="tcpForm2" name="tcpmydate"
                                  class="custom-date-picker-input" [ngClass]="tcpData.exam_status===4 ? 'custom-width':''"
                                  [options]="myDatePickerOptions" mdbValidate mdbInput [(ngModel)]="tcpData.date_of_exam"
                                  [placeholder]="'Scheduled date'" required>
                                </mdb-date-picker>
                              </div>
                              <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                                *ngIf="tcpData.exam_status===4 || tcpData.exam_status===7">
                                <mdb-date-picker [outlineInput]="true" id="tcp2form2" name="tcp2mydate"
                                  class="custom-date-picker-input" [ngClass]="tcpData.exam_status===4 ? 'custom-width':''"
                                  [options]="passedDateOption" mdbValidate mdbInput [(ngModel)]="tcpData.date_of_exam"
                                  [placeholder]="'Exam date'" required>
                                </mdb-date-picker>
                              </div>
                            </div>
                            <div class="col mx-1 px-0" *ngIf="tcpData.exam_status===4">
                              <div class="md-form mt-1 mb-2 gamePlan-Subject-input"
                                [ngClass]="tcpData.exam_status===4 ? 'custom-width-score':''">
                                <ng-select [items]="scoreArray" name="tcpscore" [(ngModel)]="tcpData.score"
                                  placeholder="Choose score" required>
                                </ng-select>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="game-sub-itm" [ngClass]="isMobile?'px-0':''">
                    <div class="mt-4 d-flex align-content-around align-items-center">
                      <div class="d-flex flex-column mx-auto game-plan-block-size subject-block-isc">
        
                        <form name="iscForm" #iscForm="ngForm" class="mx-auto w-100">
                          <div class="game-sub-title" style="margin: 5px;text-align: center; font-family: var(--san_francisco_bold); margin-bottom: 10px; font-size: 16px; color: #212529; font-style: normal; line-height: normal; letter-spacing: 0.36px;">
                            Information Systems and Control</div>
                          <div style="margin: 5px;" class="md-form">
                            <ng-select class="mb-3 w-100 mx-auto" [ngClass]="!isMobile?'custom':'custom-mobile'"
                              [items]="na_statusList" name="iscstatusList" bindLabel="exam_status_type"
                              [(ngModel)]="iscData.exam_status" bindValue="exam_status_id" placeholder="Choose option" required
                              (change)="iscChange()">
                            </ng-select>
                          </div>
        
                          <div class="game-modal-fileds">
                            <div class="col mx-1 px-0">
                              <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                                *ngIf="iscData.exam_status===1 || iscData.exam_status===2 || iscData.exam_status===5">
                                <input type="text" id="iscplannercalender" class="bg-white form-control" name="iscdate"
                                  [(ngModel)]="iscData.date_of_exam" (focus)="monthModal.show();switchId=7" required mdbInput
                                  mdbValidate>
                                <label for="iscplannercalender">Planned Month</label>
                              </div>
                              <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                                *ngIf="iscData.exam_status===3 || iscData.exam_status===6">
                                <mdb-date-picker [outlineInput]="true" id="iscForm2" name="iscmydate"
                                  class="custom-date-picker-input" [ngClass]="iscData.exam_status===4 ? 'custom-width':''"
                                  [options]="myDatePickerOptions" mdbValidate mdbInput [(ngModel)]="iscData.date_of_exam"
                                  [placeholder]="'Scheduled date'" required>
                                </mdb-date-picker>
                              </div>
                              <div class="md-form md-outline mt-1 mb-2 gamePlan-Subject-input"
                                *ngIf="iscData.exam_status===4 || iscData.exam_status===7">
                                <mdb-date-picker [outlineInput]="true" id="isc2form2" name="isc2mydate"
                                  class="custom-date-picker-input" [ngClass]="iscData.exam_status===4 ? 'custom-width':''"
                                  [options]="passedDateOption" mdbValidate mdbInput [(ngModel)]="iscData.date_of_exam"
                                  [placeholder]="'Exam date'" required>
                                </mdb-date-picker>
                              </div>
                            </div>
                            <div class="col mx-1 px-0" *ngIf="iscData.exam_status===4">
                              <div class="md-form mt-1 mb-2 gamePlan-Subject-input"
                                [ngClass]="iscData.exam_status===4 ? 'custom-width-score':''">
                                <ng-select [items]="scoreArray" name="iscscore" [(ngModel)]="iscData.score"
                                  placeholder="Choose score" required>
                                </ng-select>
                              </div>
                            </div>
                          </div>
                        </form>
        
                      </div>
                    </div>
        
                  </div>
                </div>
              </mdb-accordion-item-body>
            </mdb-accordion-item>
          </mdb-accordion> -->
        </div>
      </div>
      <!-- <app-eva-confirmation></app-eva-confirmation> -->
    </div>

    <!-- <ng-container *ngIf="showEvaluation">

</ng-container> -->


  </div>
  <div class="modal-footer" style="padding: 0px 30px;background: #f0f4f7;" *ngIf="subjectStatus">
    <button type="button" mdbBtn size="md" color="primary" class="relative waves-light" mdbWavesEffect
      (click)="continueGameplan()" [disabled]="isButtonDisabled" style="background: #06345B !important;">Continue</button>
  </div>
</div>
<div mdbModal #monthModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mymonthModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header p-2" style="background: #f7f7f7;">
        <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button> -->
        <button mdbBtn type="button" color="light" size="sm" mdbWavesEffect
          style="background: #efefef !important; box-shadow: none; padding: 10px 15px; border: 1px solid #f5f2f2;"
          [ngClass]="year>currentYear?'':'disabled'" (click)="preYear()"><i class="fas fa-angle-left"></i></button>
        <button mdbBtn type="button" color="light" size="sm" mdbWavesEffect
          style="background: #efefef !important; box-shadow: none; padding: 10px 15px; border: 1px solid #f5f2f2;">{{year}}</button>
        <button mdbBtn type="button" color="light" size="sm" mdbWavesEffect
          style="background: #efefef !important; box-shadow: none; padding: 10px 15px; border: 1px solid #f5f2f2;"
          (click)="nextYear()"><i class="fas fa-angle-right"></i></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-3" *ngFor="let row of monthService.month;let i=index" style="padding-left: 5px;">
            <button mdbBtn type="button" color="light" size="sm" mdbWavesEffect
              [ngClass]="year===currentYear && currentMonth>i?'disabled':''"
              (click)="year===currentYear && currentMonth>i?'':setDate(row);monthModal.hide();"
              style="background: #efefef !important; box-shadow: none; padding: 10px 5px; border: 1px solid #f5f2f2; width: 50px;">{{row.month}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>