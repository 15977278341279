<nav class="desktop_sidebar">
    <div class="sidebar-top">
        <img src="https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Header/logo.png" class="logo" alt="">
    </div>
    <div class="px_spc">
        <!-- <div *ngIf="menuShowNow"
      class="select_subject shadow-sm d-flex justify-content-between align-items-center {{showBackDrop && 'show-backdrop'}}"
      [style.background-color]="dropdownBackgroundColor" (click)="showBackDrop=!showBackDrop"
      (blur)="showBackDrop=false" (mouseleave)="showBackDrop=false">
      <span>{{subjectName.subject_name}}</span>
      <span><i class="fa fa-refresh" aria-hidden="true"></i></span>
      <div class="nav-menu-options">
        <ul>
          <li [ngClass]="{
            'dis-option' : selectedOption===option.subject_id_encrypt
          }" (click)="selectedOption=option.subject_id_encrypt;navgigateToLaunchpad()" *ngFor=" let option of options">
            {{ option.subject_name}}</li>
        </ul>
      </div>
    </div> -->
        <div class="sidebar-links">
            <ul>
                <li *ngIf="userData?.iimi_university_id !== null && userData?.iimi_university_id !== ''">
                    <a routerLink="/lms/university-programme" routerLinkActive="active">
                        <div class="icon">
                            <span *ngIf="userData?.is_iimi_jagsom == 1" class="sidebar_icons iimi"></span>
                            <img *ngIf="userData?.is_iimi_jagsom == 2"
                                src="https://storage.googleapis.com/miles-next_website-lms-gptool/educational_documents/Jagsom/JAGSOM.svg"
                                class="iimi">
                        </div>
                        <span *ngIf="userData?.is_iimi_jagsom == 1" class="link hide">IIMI-PGCIAA</span>
                        <span *ngIf="userData?.is_iimi_jagsom == 2" class="link hide">JAGSoM: PGCMIAA</span>
                    </a>
                </li>

                <!-- <li>
                    <a routerLink="/lms/notification" routerLinkActive="active">
                      <div class="icon">
                        <span class="notification"><i class="fa-solid fa-bell"></i></span>
                      </div>
                      <span class="link hide">Notification</span>
                    </a>
                  </li> -->

                <li>
                    <a routerLink="/lms/notification" routerLinkActive="active" class="notification_menu">
                        <div class="icon">
                            <span class="notification" *ngIf="notificationCount == 0"><i class="fa-solid fa-bell"></i></span>
                            <span class="notification" *ngIf="notificationCount > 0"><img width="32px" src="../../../assets/img/ringging_bell.gif"></span>
                          </div>
                        <span class="link hide">Notification</span>
                        <div class="notify_count">
                        <span
                            style="padding: 0.5em 0.6em !important;" class="badge bg-danger ms-2" *ngIf="notificationCount > 0">{{notificationCount}}
                        </span>
                      </div>
                    </a>
                </li>
                <li>
                  <a routerLink="/lms/feedback-notify" routerLinkActive="active">
                    <div class="icon">
                      <span class="feedback"><i class="fa-solid fa-message-lines"></i></span>
                    </div>
                    <span class="link hide">Feedback</span>
                  </a>
                </li>
                <li>
                  <a routerLink="/lms/helpdesk" routerLinkActive="active">
                    <div class="icon">
                      <span class="helpdesk"><i class="fa-brands fa-teamspeak"></i></span>
                    </div>
                    <span class="link hide">Helpdesk</span>
                  </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="sidebar-footer" mdbDropdown [dropup]="true">
        <div class="exam_status">
            <img src="https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Header/dots_icon.svg"
                class="dots_icon" mdbDropdownToggle>
            <img [src]="userData.profile_img?userData.profile_img:'https://storage.googleapis.com/miles-next_website-lms-gptool/LMS_2024_newImages/dummy_user_icon.png'"
                class="img-fluid profile_pic" [ngClass]="userData.profile_img?'':'bg-white'"
                [ngStyle]="{'padding': userData.profile_img ? '' : '0.1rem'}">
            <div class="align-self-center">
                <p class="name">{{userData.stud_name}}</p>
            </div>
            <div class="dropdown-menu footer_menu_dropdown">
                <ul>
                    <li style="overflow-wrap: break-word;">
                        <div mdbTooltip="{{userData.email}}" placement="top"
                            style="white-space: nowrap;width: 95%;overflow: hidden;text-overflow: ellipsis;">
                            <i class="fa fa-user mr-2" aria-hidden="true"></i>
                            {{userData.email}}
                        </div>
                    </li>
                    <hr style="margin: 0px;background: #a6a6a6;">
                    <li><a routerLink="/lms/change-password">Change Password</a></li>
                    <li><a (click)="logout()">Logout</a></li>
                </ul>
            </div>
        </div>
        <!-- <div>
        <p class="email">{{userData.email}}</p>
      </div> -->
    </div>
    <!-- <div>
      <p class="email">{{userData.email}}</p>
    </div> -->
</nav>

<div style="position: relative;padding-bottom:30px;">
    <router-outlet></router-outlet>
</div>