import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class JobsService {
  url = `${environment.apiUrl}`;
  cmaurl = `${environment.cmaapiUrl}`;
  viewCandJobDetailsData = new Subject();
  zoomListLmsWebinarData = new Subject();
  zoomListLmsWebinarMetadata = new Subject();
  webniarListCategory = new Subject();
  materialData = new Subject();
  bookListData = new Subject();
  getbookordersinformationData = new Subject();
  getbookordersinformationMetadata = new Subject();
  userData: any;
  ebooks: BehaviorSubject<any> = new BehaviorSubject([]);
  ebooksSubtopicName: BehaviorSubject<any> = new BehaviorSubject([]);
  ebooksMetadata: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(private http: HttpClient) { }
  savejobprofiledetails(param) {
    return this.http.post(this.url + 'savejobprofiledetails', param);
    // this.http.post(this.url + 'gettotaluserattendance', param ).subscribe((res: any) => {
    //   if (res) {
    //     this.viewCandJobDetailsData.next(res['data']);
    //   }
    // });
  }
  getjobprofiledetails() {
    return this.http.post(this.url + 'getjobprofiledetails', {});
  }
  zoomListLmsWebinar(param) {
    this.http
      .post(this.url + 'admin/webinar/zoomListLmsWebinar', param)
      .subscribe((res: any) => {
        if (res) {
          this.zoomListLmsWebinarData.next(res['data']);
          this.zoomListLmsWebinarMetadata.next(res['metadata']);
        }
      });
  }
  getcategorybycategorytype(params: any) {
    this.http
      .post(this.url + 'admin/webinar/getcategorybycategorytype', params)
      .subscribe((res: any) => {
        if (res) {
          this.webniarListCategory.next(res);
        }
      });
  }

  updateMaterial(params: any) {
    this.http
      .post(this.url + 'updateMaterial', params)
      .subscribe((res: any) => {
        if (res) {
          this.materialData.next(res.status);
        }
      });
  }

  updateMaterialUploads(params: any) {
    this.http
      .post(this.url + 'updateMaterialUploads', params)
      .subscribe((res: any) => {
        if (res) {
          this.materialData.next(res.status);
        }
      });
  }

insertMaterialUploads(param) {
  return this.http.post(this.url + 'insertMaterialUploads', param)
  }
  insertCourseMaterial(param){
    return this.http.post(this.url + 'insertCourseMaterial', param)
    }

  registerUser(param) {
    return this.http.post(this.url + 'admin/webinar/zoomRegister', param);
  }

  registerUsertomeeting(param) {
    return this.http.post(this.url + 'admin/zoom/registerMeeting', param);
  }

  getbookordersinformation() {
    this.http
      .get(this.url + 'getbookordersinformation', {})
      .subscribe((res: any) => {
        this.getbookordersinformationData.next(res['data']);
        this.getbookordersinformationMetadata.next(res['metadata']);
        //  console.log(this.getbookordersinformationData);
      });
  }
  placeorder(param) {
    return this.http.post(this.url + 'placeorder', param);
  }
  applybookpromocode(param) {
    return this.http.post(this.url + 'applybookpromocode', param);
  }
  aicpiPayment() {
    return this.http.post(this.url + 'aicpaPaymentinformation', {});
  }

  aicpiInterPayment() {
    return this.http.post(this.url + 'aicpaInterPaymentinformation', {});
  }

  getAicpaBatches(payload) {
    return this.http.post(this.url + 'aicpaBatchAccess', payload);
  }
  insertOtherResources(payload) {
    return this.http.post(this.url + 'insertOtherResources', payload);
  }

  insertAdditionalUpdates(payload) {
    return this.http.post(this.url + 'insertAdditionalUpdates', payload);
  }

  getOtherResources(payload) {
    return this.http.post(this.url + 'getOtherResources', payload);
  }
  getCpaInterOtherResources(payload) {
    return this.http.post(this.url + 'getInterOtherResources', payload);
  }

  getMainTopicAicpa(payload) {
    return this.http.post(this.url + 'subject/getMainTopic', payload);
  }
  getSubTopicAicpa(payload) {
    return this.http.post(this.url + 'subject/getSubTopic', payload);
  }

  getVideoList(subject_id, subtopic_id) {
    return this.http.post(this.url + 'subject/getVideoList', {
      subtopic_id,
      subject_id,
    });
  }
  saveVideoView(video_id) {
    this.http
      .post(this.url + 'subject/saveVideoView', { video_id })
      .subscribe();
  }
  getAvailableCredits(subject_id) {
    return this.http.post(this.url + 'getAvailableCredits', { subject_id });
  }
  redeemVideoAccess(subject_id, credit_points, video_id) {
    return this.http.post(this.url + 'redeemVideoAccess', {
      subject_id,
      credit_points,
      video_id,
    });
  }

  ebook(subject_id, subtopic_id, old_year) {
    console.log('year', { subject_id, subtopic_id, old_year });
    const params =
      old_year == 1
        ? { subject_id, subtopic_id, old_year }
        : { subject_id, subtopic_id };
    this.http.post(this.url + 'geteBooks', params).subscribe((res: any) => {
      // console.log('res', res)
      if (res && res.data.length) {
        this.ebooks.next(res.data);
        this.ebooksSubtopicName.next(res.data[0].subtopic_name);
        this.ebooksMetadata.next(res.metadata[0]);
      } else {
        Swal.fire({
          icon: 'error',
          text: ' Coming Soon!',
          allowOutsideClick: false,
        }).then((res) => {
          window.close();
        });
      }
    });
  }
  saveEbookNotes(params) {
    return this.http.post(this.url + 'saveEbookNotes', { ...params });
  }

  // Assesment AICPA Exam
  getExamList(params) {
    return this.http.post(this.url + 'practicetest/getExamList', params);
  }

  getExamScoreList(params) {
    return this.http.post(this.url + 'practicetest/getExamScoreList', params);
  }

  generateSession(params) {
    return this.http.post(this.url + 'practicetest/generateSession', params);
  }

  fetchMcqQuestions(params) {
    return this.http.post(this.url + 'practicetest/fetchMcqQuestions', params);
  }

  saveAttemptedAnswer(params) {
    return this.http.post(
      this.url + 'practicetest/saveAttemptedAnswer',
      params
    );
  }

  onExamSubmit(params) {
    return this.http.post(this.url + 'practicetest/onExamSubmit', params);
  }

  getMcqdetailedresults(params) {
    return this.http.post(
      this.url + 'practicetest/getMcqdetailedresults',
      params
    );
  }

  getContents(params) {
    return this.http.post(this.url + 'getContent', params);
  }

  insertContents(params) {
    return this.http.post(this.url + 'addContent', params);
  }

  insertContentMaterial(params: any) {
    return this.http.post(this.url + 'insertContentMaterial', params);
  }
  deleteContents(payload) {
    return this.http.post(this.url + 'deleteContent', payload);
  }

  insertLiquiLoansForm(payload) {
    return this.http.post(this.url + 'createLiquiLoan', payload);
  }
  getRecord(payload) {
    return this.http.post(this.url + 'loanRecord', payload);
  }

  insertLiquiLoanDocForm(payload) {
    // let headers = new HttpHeaders();
    // headers = headers.append('mimeType', 'multipart/form-data');
    return this.http.post(this.url + 'uploadDocument', payload);
  }

  getLoanStatus() {
    return this.http.get(this.url + 'getLoanStatus');
  }

  insertjainresources(payload) {
    return this.http.post(this.cmaurl + 'insertjainresources', payload);
  }

  getjainresources() {
    return this.http.post(this.cmaurl + 'getjainresources', {});
  }

  deletejainresourcedoc(payload) {
    return this.http.post(this.cmaurl + 'deletejainresourcedoc', payload);
  }
  aicpanewbatchstartnotify() {
    return this.http.post(this.url + 'saveaicpanotify', {});
  }

  insertAssignmentMaterial(params: any) {
    return this.http
      .post(this.url + 'insertIImiAssignment', params)

  }

  getCityList() {
    return this.http.post(this.url + 'cityList', {});
  }

  getF2FWebinarByCity(params) {
    return this.http.post(this.url + 'getDates', params);
  }

  getTimeDetailsByDate(params) {
    return this.http.post(this.url + 'getTime', params);
  }

  getStudentEnrollmentDate() {
    return this.http.post(this.url + 'checkStudentEnrollment', {});
  }
}
