
<section class="fixed-top">
    <section class="exam_section">
      <div class="row">
        <div class="col-8 col-sm-8 col-md-6 col-lg-6">
          <h1 class="d-none d-md-block">{{sessionChapterValue}}</h1>
          <h1 class="d-block d-md-none" style="font-size: 13px;margin-top: 20px;margin-left: -10px;padding-left: 0px;">
            {{sessionChapterValue}}</h1>
        </div>
        <div class="col-4 col-sm-4 col-md-6 col-lg-6">
          <div class="mcq_header_list">
            <ul>
              <li (click)="click('quitmodal')"><i class="fad fa-lock"></i>Quit</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="exam_section2" *ngIf="session_id">
      <div class="row">
        <div class="col-6 p-0 pl-0 pl-md-3">
          <h1>
            <i class="fas fa-stopwatch" aria-hidden="true"></i>
            <span
              class="small ml-2">{{hours>0?hours+':':''}}{{minute<=9?0:''}}{{minute}}:{{countdown<=9?0:''}}{{countdown}}</span>
          </h1>
        </div>
        <div class="col-6 p-0 px-0 px-md-3">
          <div class="mcq_header_list2">
            <ul>
              <li (click)="isCalModal=true;showCal=true">
                <i class="fad fa-calculator" aria-hidden="true"></i>
                <span class="d-none d-sm-none d-md-none d-lg-inline-block">Calculator</span>
              </li>
             
              <li *ngIf="showSubmit" (click)="click('submittestmodal');"><i
                  class="fad fa-paper-plane"></i>
                <span class="d-none d-sm-none d-md-none d-lg-inline-block">Submit Test</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section *ngIf="!wctMode">
      <div class="miles-mcq-progress-bar">
        <div class="progress" style="border-radius: 0;background: #eeeeee!important;height: 7px;">
          <div class="progress-bar bg-danger progress-bar-striped progress-bar-animated" role="progressbar"
            [ngStyle]="{width: examProgressBar+'%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    </section>
  
    <div style="position: fixed;right: 0px;bottom: 0px;border: 1px solid rgb(0, 0, 0);border-radius: 5px 5px 0px 0px;max-width: 515px;" [ngStyle]="{'width': showCal?'100%':'100px'}" *ngIf="isCalModal">
      <div class="modal-header py-2" style="background: #000;border-bottom: 1px solid;color: #ffff;">
        <span style="position: absolute;right: 35px;" aria-label="minimize" (click)="showCal=!showCal">
          <span *ngIf="showCal" aria-hidden="true">  <i class="fas fa-compress-alt"></i> </span>
          <span *ngIf="!showCal" aria-hidden="true"> <i class="fas fa-expand-alt"></i> </span>
        </span>
        <span style="position: absolute; right: 10px;" aria-label="Close" (click)="isCalModal=false">
          <span aria-hidden="true"><i class="fas fa-times-square"></i></span>
        </span>
        <h6 class="modal-title w-100 font-weight-bold mr-2" style="color: #d74c15;text-shadow: .5px 0px 30px #fff;" id="CalculatorModalLabel"  (click)="showCal=!showCal">
          <i class="fas fa-calculator mr-2"></i>
          <span *ngIf="showCal">Calculator</span>
        </h6>
      </div>
      <div class="modal_content p-0" *ngIf="showCal">
        <span class="small text-center position-absolute" style="margin: 15px 30px;">Loading calculator, please wait...</span>
        <iframe src="https://factory.mileseducation.com/Calculator/Calculator.html" class="calculator"></iframe>
      </div>
    </div>
  
  
  </section>
  <router-outlet></router-outlet>
  
  
  <!-- calculator Modal -->
  <div id="calculatormodal" class="modal-window">
    <div class="modal_body">
      <div class="modal_header">
        <h1><i class="fad fa-calculator"></i> Calculator</h1>
        <span title="Close" class="modal-close" (click)="click('calculatormodal')"><i class="fad fa-times"></i></span>
      </div>
      <div class="modal_content">
  
        <iframe src="https://factory.mileseducation.com/Calculator/Calculator.html" class="calculator"
          style="overflow-y: hidden; height:300px;width:99% !important;"></iframe>
  
      </div>
    </div>
  </div>
  
  <!-- Submit Test Modal -->
  <div id="submittestmodal" class="modal-window">
    <div class="modal_body">
      <div class="modal_header">
        <!-- <p><span><i class="fad fa-exclamation"></i></span> You have skipped one or more questions in this testlet.</p> -->
        <span title="Close" class="modal-close" (click)="click('submittestmodal')"><i class="fad fa-times"></i></span>
      </div>
      <div class="modal_content">
        <p class="text-danger text-center" *ngIf="isError"><i class="fas fa-exclamation-triangle"></i> Invalid Session</p>
        <p>Click on “Submit Test” to confirm your submission, or click on "Return To Test" to return back to the test.</p>
      </div>
      <div class="modal_footer">
        <button type="button" class="btn save_btn" (click)="submitExam();">Submit Test</button>
        <button type="button" class="btn return_btn" (click)="click('submittestmodal')">Return To Test</button>
      </div>
    </div>
  </div>
  
  <!-- Quit Modal -->
  <div id="quitmodal" class="modal-window">
    <div class="modal_body">
      <div class="modal_header">
        <span class="modal-title w-100 modal-close text-left pl-3"><i
            class="fad fa-exclamation-triangle mr-2"></i>Attention</span>
        <span title="Close" class="modal-close" (click)="click('quitmodal')"><i class="fad fa-times"></i></span>
      </div>
      <div class="modal_content text-center" *ngIf="session_id">
        <span>You will lose all attempted answers.<br>Do you really want to quit?</span>
      </div>
      <div class="modal_content text-center" *ngIf="!session_id">
        <span>This is an Invalid Session, Please check.</span>
      </div>
      <div class="modal_footer py-1 px-2">
        <button type="button" class="btn save_btn" (click)="quit();click('quitmodal')">Quit</button>
      </div>
    </div>
  </div>
  