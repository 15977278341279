import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JainMcomCourseService {
  private url = `${environment.cmaapiUrl}`;
  mcomChapterListObservable = new Subject();
  metaDataListDataObservable = new Subject();
  mcomChapterEbookObservable = new Subject();
  mcomChapterEbookMetadataObservable = new Subject();
  mcomChapterVideoObservable = new Subject();
  mcomChapterVideoMetadataObservable = new Subject();
  mcomChapterVideoDetailsObservable = new Subject();
  mcomChapterVideoDetailsMetadataObservable = new Subject();
  mcomChapterMcqListObservable = new Subject();
  mcomChapterMcqListMetadataObservable = new Subject();
  mcomAssignmentMcqListObservable = new Subject();
  mcomAssignmentMcqListMetadataObservable = new Subject();
  questionId: Subject<any> = new Subject();
  attemptedQuestion: Subject<any> = new Subject();
  exam_assignment: Subject<any> = new Subject();
  sessionChapter: Subject<any> = new Subject();
  practice_assignment: Subject<any> = new Subject();
  mcomSessionResultObservable = new Subject();
  mcomSessionResultMetadataObservable = new Subject();
  chapterAnalysisObservable = new Subject();
  chapterAnalyticsMetadataObservable =  new Subject();


  constructor(private http: HttpClient) {}

  getMcomCourseData(){
    this.http.post(this.url + 'mcom_chapterslist', {}).subscribe((res: any) => {
      if (res && res.status) {
        this.mcomChapterListObservable.next(res['data']);
        this.metaDataListDataObservable.next(res?.metadata[0]);
      }
    });
  }
  getMcomCourseEbook(param){
    this.http.post(this.url + 'chapter_ebooks', param).subscribe((res: any) => {
      if (res && res.status) {
        this.mcomChapterEbookObservable.next(res['data']);
        this.mcomChapterEbookMetadataObservable.next(res.metadata[0]);
      }
    });
  }
  getMcomCourseVideo(param){
    this.http.post(this.url + 'chapter_videos', param).subscribe((res: any) => {
      if (res && res.status) {
        this.mcomChapterVideoObservable.next(res['data']);
        this.mcomChapterVideoMetadataObservable.next(res.metadata[0]);
      }
    });
  }

  getMcomCourseVideoDetails(param){
    this.http.post(this.url + 'chapter_video_details', param).subscribe((res: any) => {
      if (res && res.status) {
        this.mcomChapterVideoDetailsObservable.next(res['data']);
        this.mcomChapterVideoDetailsMetadataObservable.next(res.metadata[0]);
      }
    });
  }

  getMcomChapterMcqList(param){
    this.http.post(this.url + 'chapter_mcq_session', param).subscribe((res: any) => {
      if (res) {
        this.mcomChapterMcqListObservable.next(res['data']);
        this.mcomChapterMcqListMetadataObservable.next(res.metadata[0]);
      }
    });
  }
  getMcomAssignmentMcqList(param){
    this.http.post(this.url + 'chapter_mcq_session', param).subscribe((res: any) => {
      if (res) {
        this.mcomAssignmentMcqListObservable.next(res['data']);
        this.mcomAssignmentMcqListMetadataObservable.next(res.metadata[0]);
      }
    });
  }
  saveAnswer(selected_option, question_id, session_id, chapter_id) {
    return this.http.post(this.url + 'saveMcomAnswer', { selected_option, question_id, session_id, chapter_id });
  }

  getMcomSessionResults(param){
    this.http.post(this.url + 'mcomsessionresults', param).subscribe((res: any) => {
      this.mcomSessionResultObservable.next(res['data']);
        this.mcomSessionResultMetadataObservable.next(res.metadata[0]);
    }, (err => {
      console.log('jain-service', err)
    }));
  }

  setExam_assignment(session_id,cat){
    if(cat == 1){ this.practice_assignment.next(session_id); }
    if(cat == 2){ this.exam_assignment.next(session_id); }
  }

  assignmentCheck(assignment_number) {
    return this.http.post(this.url + 'assignment_check', {"assignment_number" : assignment_number});
  }
  
  chapterAnalytics(param){
    this.http.post(this.url + '/chapter_analytics', param).subscribe((res: any)=>{
      if(res){
        this.chapterAnalysisObservable.next(res['data']);
        this.chapterAnalyticsMetadataObservable.next(res.metadata[0]);
      }
      
    })
  }

}
