import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'secToTime',
})

export class secToTime implements PipeTransform {
    constructor() { }
    transform(value: any): any {
        return new Date(value * 1000).toISOString().slice(11, 19);
    }
}