<nav  class="desktop_sidebar">
    <div class="sidebar-top">
        <img src="{{verticalData?.course_logo}}" class="logo" alt="">
    </div>
    <div class="px_spc">

        <div class="sidebar-links">
            <ul>
              <ul>
                <!-- University Programme -->
                <li *ngIf="userData?.iimi_university_id !== null && userData?.iimi_university_id !== ''">
                  <a routerLink="/lms/university-programme" 
                     routerLinkActive="active" 
                     [class.disabled]="isButtonDisabled()" 
                     [ngStyle]="{'pointer-events': isButtonDisabled() ? 'none' : 'auto', 
                                 'opacity': isButtonDisabled() ? '0.5' : '1'}">
                     
                    <div class="icon">
                      <img src="{{verticalData?.menu_icon}}" class="iimi">
                    </div>
                    <div class="mainMenu">
                      <span class="link hide">{{verticalData?.menu_name}}</span>
                    </div>
                  </a>
                </li>
              
                <!-- Notification -->
                <li>
                  <a routerLink="/lms/notification" 
                     routerLinkActive="active" 
                     class="notification_menu" 
                     [class.disabled]="isButtonDisabled()" 
                     [ngStyle]="{'pointer-events': isButtonDisabled() ? 'none' : 'auto', 
                                 'opacity': isButtonDisabled() ? '0.5' : '1'}">
                     
                    <div class="icon">
                      <span class="notification" *ngIf="notificationCount == 0">
                        <i class="fa-solid fa-bell"></i>
                      </span>
                      <span class="notification" *ngIf="notificationCount > 0">
                        <img width="32px" src="../../../assets/img/ringging_bell.gif">
                      </span>
                    </div>
                    <div class="menuName">
                      <span class="link hide">Notification</span>
                      <div [ngClass]="notificationCount > 0 ? 'notify_counts': 'notify_count'">
                        <span style="padding: 0.5em 0.6em !important;" class="badge bg-danger ms-2" *ngIf="notificationCount > 0">
                          {{notificationCount}}
                        </span>
                      </div>
                    </div>
                  </a>
                </li>
              
                <!-- Feedback -->
                <li>
                  <a routerLink="/lms/feedback-notify" 
                     routerLinkActive="active" 
                     [class.disabled]="isButtonDisabled()" 
                     [ngStyle]="{'pointer-events': isButtonDisabled() ? 'none' : 'auto', 
                                 'opacity': isButtonDisabled() ? '0.5' : '1'}">
                                 
                    <div class="icon">
                      <span class="feedback"><i class="fa-solid fa-message-lines"></i></span>
                    </div>
                    <div class="menuName">
                      <span class="link hide">Feedback</span>
                    </div>
                  </a>
                </li>
              
                <!-- Helpdesk -->
                <li>
                  <a routerLink="/lms/helpdesk" 
                     routerLinkActive="active" 
                     [class.disabled]="isButtonDisabled()" 
                     [ngStyle]="{'pointer-events': isButtonDisabled() ? 'none' : 'auto', 
                                 'opacity': isButtonDisabled() ? '0.5' : '1'}">
                                 
                    <div class="icon">
                      <span class="helpdesk"><i class="fa-brands fa-teamspeak"></i></span>
                    </div>
                    <div class="menuName">
                      <span class="link hide">Helpdesk</span>
                    </div>
                  </a>
                </li>
              
                <!-- KYC Details (This should always be enabled, so no condition applied) -->
                <li *ngIf="disable">
                  <a routerLink="/lms/student-details" routerLinkActive="active">
                    <div class="icon">
                      <span class="helpdesk"><i class="fa-solid fa-money-check-dollar"></i></span>
                    </div>
                    <div class="menuName">
                      <span class="link hide">KYC Details</span>
                    </div>
                  </a>
                </li>
              </ul>
              
            </ul>
        </div>
    </div>

    <div class="sidebar-footer" mdbDropdown [dropup]="true">
        <div class="exam_status">
            <img src="https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Header/dots_icon.svg"
                class="dots_icon" mdbDropdownToggle>
            <img [src]="userData.profile_img?userData.profile_img:'https://storage.googleapis.com/miles-next_website-lms-gptool/LMS_2024_newImages/dummy_user_icon.png'"
                class="img-fluid profile_pic" [ngClass]="userData.profile_img?'':'bg-white'"
                [ngStyle]="{'padding': userData.profile_img ? '' : '0.1rem'}">
            <div class="align-self-center">
                <p class="name">{{userData.stud_name}}</p>
            </div>
            <div class="dropdown-menu footer_menu_dropdown">
                <ul>
                    <li style="overflow-wrap: break-word;">
                        <div mdbTooltip="{{userData.email}}" placement="top"
                            style="white-space: nowrap;width: 95%;overflow: hidden;text-overflow: ellipsis;">
                            <i class="fa fa-user mr-2" aria-hidden="true"></i>
                            {{userData.email}}
                        </div>
                    </li>
                    <hr style="margin: 0px;background: #a6a6a6;">
                    <li><a routerLink="/lms/change-password">Change Password</a></li>
                    <li><a (click)="logout()">Logout</a></li>
                </ul>
            </div>
        </div>
    </div>
</nav>



<section class="mobile_header">

  <!-- <img src="https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Header/logo.png" class="logo" alt=""> -->
  <img src="{{verticalData?.course_logo}}" class="logo" alt="">
  <label for="check">
    <input type="checkbox" id="check">
    <span (click)="showMobileSideBar=!showMobileSideBar"><img
        src="https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Header/Burger_menu.svg" id="btn"></span>

    <span (click)="showMobileSideBar=!showMobileSideBar"><img
        src="https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Header/close_icon.svg" id="cancel"
        [ngStyle]="{'left' : showMobileSideBar ? '220px' : '-195px' }"></span>
  </label>

  <div class="sidebar" [ngStyle]="{'left' : showMobileSideBar ? '0px' : '-780px' }">
    <nav>
      <div class="sidebar-top">
        <img src="https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Header/logo.png" class="logo" alt="">
      </div>
      <div class="px_spc">
        <div class="sidebar-links">
          <ul>
            <li *ngIf="userData?.iimi_university_id !== null && userData?.iimi_university_id !== ''">
                <a (click)="closeMobileBtn()" routerLink="/lms/university-programme" routerLinkActive="active">
                    <div class="icon">
                        <!-- <span *ngIf="userData?.is_iimi_jagsom == 1" class="sidebar_icons iimi"></span> -->
                        <img 
                            src="{{verticalData?.menu_icon}}"
                            class="iimi">
                    </div>
                    <div class="mainMenu">
                      <span  class="link hide">{{verticalData?.menu_name}}</span>
                    </div>
                    <!-- <span *ngIf="userData?.is_iimi_jagsom == 1" class="link hide">IIMI-PGCIAA</span> -->
                </a>
            </li>

            <li>
                <a (click)="closeMobileBtn()" routerLink="/lms/notification" routerLinkActive="active" class="notification_menu">
                    <div class="icon">
                        <span class="notification" *ngIf="notificationCount == 0"><i class="fa-solid fa-bell"></i></span>
                        <span class="notification" *ngIf="notificationCount > 0"><img width="32px" src="../../../assets/img/ringging_bell.gif"></span>
                      </div>
                      <div class="menuName">
                        <span class="link hide">Notification</span>
                          <div [ngClass]="notificationCount > 0 ? 'notify_counts': 'notify_count'">
                            <span
                                style="padding: 0.5em 0.6em !important;" class="badge bg-danger ms-2" *ngIf="notificationCount > 0">{{notificationCount}}
                            </span>
                        </div>
                      </div>
                </a>
            </li>
            <li>
              <a (click)="closeMobileBtn()" routerLink="/lms/feedback-notify" routerLinkActive="active">
                <div class="icon">
                  <span class="feedback"><i class="fa-solid fa-message-lines"></i></span>
                </div>
                <div class="menuName">
                  <span class="link hide">Feedback</span>
                </div>
              </a>
            </li>
            <li>
              <a (click)="closeMobileBtn()" routerLink="/lms/helpdesk" routerLinkActive="active">
                <div class="icon">
                  <span class="helpdesk"><i class="fa-brands fa-teamspeak"></i></span>
                </div>
                <div class="menuName">
                  <span class="link hide">Helpdesk</span>
                </div>
              </a>
            </li>
        </ul>
        </div>
      </div>
      <div class="sidebar-footer" mdbDropdown [dropup]="true">
        <div class="exam_status">
          <img src="https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Header/dots_icon.svg"
            class="dots_icon" mdbDropdownToggle>
          <img
            [src]="userData.profile_img?userData.profile_img:'https://storage.googleapis.com/miles-next_website-lms-gptool/LMS_2024_newImages/dummy_user_icon.png'"
            class="img-fluid profile_pic" [ngClass]="userData.profile_img?'':'bg-white'"
            [ngStyle]="{'padding': userData.profile_img ? '' : '0.1rem'}">
          <div>
            <p class="name">{{userData.stud_name}}</p>
            <p class="email">{{userData.email}}</p>
          </div>
          <div class="dropdown-menu footer_menu_dropdown">
            <ul>
              <li><a routerLink="/lms/my-profile">Profile</a></li>
              <!-- <li><a routerLink="/lms/jobs/helpdesk">Helpdesk & Feedback</a></li> -->
              <li><a routerLink="/lms/change-password">Change Password</a></li>
              <li><a (click)="logout()">Logout</a></li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
</section>

<div style="position: relative;padding-bottom:30px;">
    <router-outlet></router-outlet>
</div>